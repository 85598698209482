import _isArray from 'lodash/isArray';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import _mergeWith from 'lodash/mergeWith';
import Vue from 'vue';
import moment from 'moment';
import _keyBy from 'lodash/keyBy';
import _groupBy from 'lodash/groupBy';

const falseReducer = (c, v) => (c === false ? v : c + v);
const falseNArrayMerger = (objValue, srcValue) => {
  if (objValue === false || typeof objValue === 'undefined') return srcValue;
  else if (srcValue === false || typeof srcValue === 'undefined') return objValue;
  else if (_isArray(srcValue) && _isArray(objValue)) return [...srcValue, ...objValue];
  else return objValue + srcValue;
};
const totalsReducer = (objValue, srcValue) => {
  if (objValue === false || typeof objValue === 'undefined') return srcValue;
  else if (srcValue === false || typeof srcValue === 'undefined') return objValue;
  else if (_isNumber(srcValue) && _isNumber(objValue)) return srcValue + objValue;
  else if (_isArray(srcValue) && _isArray(objValue)) return [...srcValue, ...objValue];
  else if (_isObject(srcValue) || _isObject(objValue)) return _mergeWith({}, objValue, srcValue, totalsReducer);
  else return undefined;
};
/**
 * Calculate the Gross Value
 * @param values
 * @param projectFinance
 * @param rateTable
 * @param dollarValues {boolean} - if true, return the value in dollars, otherwise return the value as cents
 * @returns {boolean|number|*}
 */
const calculateGC = (values, projectFinance, rateTable = {}, dollarValues = false) => {
  if (!values || !values.type) return false;
  switch (values.type) {
    case 'LaborV2':
      const lookupPositionRate =
        values.positionRateType !== 'manual' &&
        values.lookupPositionRate &&
        rateTable.hasOwnProperty(values.lookupPositionRate)
          ? rateTable[values.lookupPositionRate]
          : null;
      return (
        values.weeks *
        values.headCount *
        (values.regularHours *
          (values.positionRateType === 'manual'
            ? (values.manualPositionRate?.regularRate ?? 0)
            : (lookupPositionRate?.regularRate ?? 0) / (dollarValues ? 100 : 1)) +
          values.overtimeHours *
            (values.positionRateType === 'manual'
              ? (values.manualPositionRate?.overtimeRate ?? 0)
              : (lookupPositionRate?.overtimeRate ?? 0) / (dollarValues ? 100 : 1)) +
          values.holidayHours *
            (values.positionRateType === 'manual'
              ? (values.manualPositionRate?.holidayRate ?? 0)
              : (lookupPositionRate?.holidayRate ?? 0) / (dollarValues ? 100 : 1)) +
          values.specialHours *
            (values.positionRateType === 'manual'
              ? (values.manualPositionRate?.specialRate ?? 0)
              : (lookupPositionRate?.specialRate ?? 0) / (dollarValues ? 100 : 1)))
      );
    case 'Labor':
      const directLaborTMHours = (values.days || 0) * (values.dailyHours || 0) * (values.numberOfTMs || 0);
      const positionRate =
        values.positionRateType === 'manual'
          ? values.manualPositionRate || 0
          : (values.lookupPositionRate && values.lookupPositionRate.rate) || 0;
      const burdenOverheadRate =
        !values.hasOwnProperty('burdenOverheadRate') || values.burdenOverheadRate === null
          ? ((projectFinance && projectFinance.burdenOverheadRate) || 0) / (dollarValues ? 100 : 1)
          : values.burdenOverheadRate;
      const marginPercentage =
        !values.hasOwnProperty('laborGrossMargin') || values.laborGrossMargin === null
          ? (projectFinance && projectFinance.laborGrossMargin) || 0
          : values.laborGrossMargin;
      const laborGrossProfit = ((positionRate + burdenOverheadRate) / (100 - marginPercentage)) * marginPercentage;
      const billingRate = positionRate + burdenOverheadRate + laborGrossProfit;
      return directLaborTMHours * billingRate;
    case 'UnitCost':
      return values.quantity * values.unitQuantity * values.unitCost;
    case 'Quote':
      return values.quote;
    case 'Estimate':
      return values.estimate * ((projectFinance && projectFinance.unitSize) || 0);
  }
};

const calculateLBOP = (values, projectFinance, dollarValues = false) => {
  if (!values || !values.type || values.type !== 'Labor') return { burdenNOverhead: false, laborGrossProfit: false };
  const directLaborTMHours = (values.days || 0) * (values.dailyHours || 0) * (values.numberOfTMs || 0);
  const positionRate =
    values.positionRateType === 'manual'
      ? values.manualPositionRate || 0
      : (values.lookupPositionRate && values.lookupPositionRate.rate) || 0;
  const burdenOverheadRate =
    !values.hasOwnProperty('burdenOverheadRate') || values.burdenOverheadRate === null
      ? ((projectFinance && projectFinance.burdenOverheadRate) || 0) / (dollarValues ? 100 : 1)
      : values.burdenOverheadRate;
  const marginPercentage =
    !values.hasOwnProperty('laborGrossMargin') || values.laborGrossMargin === null
      ? (projectFinance && projectFinance.laborGrossMargin) || 0
      : values.laborGrossMargin;
  const laborGrossProfit = ((positionRate + burdenOverheadRate) / (100 - marginPercentage)) * marginPercentage;
  return {
    burdenNOverhead: directLaborTMHours * burdenOverheadRate,
    laborGrossProfit: directLaborTMHours * laborGrossProfit,
  };
};

const calculateProfit = (total, profitObject, projectFinance) => {
  const profitPercentage =
    profitObject && typeof profitObject.percentage === 'number'
      ? profitObject.percentage
      : (projectFinance && projectFinance.profitMargin) || 0;
  const UnadjustedGrossProfit = Math.ceil((total / (100 - profitPercentage)) * profitPercentage);
  const ProfitAdjustment = Math.ceil(
    (UnadjustedGrossProfit * ((projectFinance && projectFinance.profitAdjustment) || 0)) / 100,
  );
  const GrossProfit = UnadjustedGrossProfit + ProfitAdjustment;
  return { UnadjustedGrossProfit, ProfitAdjustment, GrossProfit };
};

const calculateCostTotal = (values = null, projectFinance = null, rateTable = {}, dollarValues = false) => {
  const baseTotal = {
    CostByType: {
      Labor: false,
      UnitCost: false,
      Quote: false,
      Estimate: false,
    },
    GrossCost: false,
    LBOP: { burdenNOverhead: false, laborGrossProfit: false },
    LaborOverhead: false,
    Taxes: false,
    Contingency: false,
    BudgetedCost: false,
    ConstructionInterest: false,
    Bonding: false,
    Insurance: false,
    FinanceOverhead: false,
    FinancedCost: false,
    UnadjustedGrossProfit: false,
    ProfitAdjustment: false,
    GrossProfit: false,
    NetCost: false,
  };
  if (!values) return baseTotal;
  const grossCost = calculateGC(values.cost, projectFinance, rateTable, dollarValues);
  if (grossCost === false) return baseTotal;
  const costByType = getCostByType(values.cost.type, grossCost);
  const lbop = calculateLBOP(values.cost, projectFinance, dollarValues);
  const laborOverhead = Object.values(lbop).reduce(falseReducer, false);
  const taxPercentage =
    values.taxes && typeof values.taxes.percentage === 'number'
      ? values.taxes.percentage
      : (projectFinance && projectFinance.taxRate) || 0;
  const taxes = ((grossCost || 0) * taxPercentage) / 100;
  const taxedGross = (grossCost || 0) + taxes;
  const contingencyPercentage =
    values.contingency && typeof values.contingency.percentage === 'number'
      ? values.contingency.percentage
      : (projectFinance && projectFinance.contingency) || 0;
  const contingency = (taxedGross * contingencyPercentage) / 100;
  const budgetedCost = taxedGross + contingency;
  //insurance
  const insurancePercentage =
    values.finance && typeof values.finance.insurancePercentage === 'number'
      ? values.finance.insurancePercentage
      : (projectFinance && projectFinance.insurance) || 0;
  const insurance = Math.ceil((budgetedCost * insurancePercentage) / 100);
  //finance
  const lePays = values.finance && values.finance.lePays;
  const constructionInterestRate = (projectFinance && projectFinance.constructionInterestRate) || 0;
  const constructionInterest = lePays
    ? Math.ceil(
        budgetedCost *
          Math.pow(
            1 + constructionInterestRate / 100 / 12,
            (projectFinance && projectFinance.constructionInterestDuration) || 0,
          ) -
          budgetedCost,
      )
    : 0;
  const bondingRate = (projectFinance && projectFinance.bondingRate) || 0;
  const bonding = lePays ? Math.ceil((budgetedCost * bondingRate) / 100) : 0;
  const financeOverhead = insurance + constructionInterest + bonding;
  const financedCost = budgetedCost + financeOverhead;
  //profit
  const {
    UnadjustedGrossProfit,
    ProfitAdjustment,
    GrossProfit: grossProfit,
  } = calculateProfit(financedCost, values.profit, projectFinance);
  const netCost = financedCost + grossProfit;

  return {
    CostByType: costByType,
    GrossCost: grossCost,
    LBOP: lbop,
    LaborOverhead: laborOverhead,
    Taxes: taxes,
    Contingency: contingency,
    BudgetedCost: budgetedCost,
    ConstructionInterest: constructionInterest,
    Bonding: bonding,
    Insurance: insurance,
    FinanceOverhead: financeOverhead,
    FinancedCost: financedCost,
    UnadjustedGrossProfit: UnadjustedGrossProfit,
    ProfitAdjustment: ProfitAdjustment,
    GrossProfit: grossProfit,
    NetCost: netCost,
  };
};

const getCostByType = (type, grossCost) => {
  const costByType = {
    Labor: false,
    UnitCost: false,
    Quote: false,
    Estimate: false,
  };
  if (!type) return costByType;
  costByType[type] = grossCost;
  return costByType;
};

const costFormObject = function (type) {
  switch (type) {
    case 'LaborV2':
      return {
        type,
        regularHours: '',
        overtimeHours: 0,
        holidayHours: 0,
        specialHours: 0,
        positionRateType: 'lookup',
        manualPositionRate: null,
        lookupPositionRate: null,
        weeks: '',
        headCount: '',
      };
    case 'Labor':
      return {
        type,
        days: '',
        dailyHours: '',
        numberOfTMs: '',
        positionRateType: 'lookup',
        manualPositionRate: '',
        lookupPositionRate: null,
        burdenOverheadRate: null,
        laborGrossMargin: null,
      };
    case 'UnitCost':
      return {
        type,
        uom: '',
        quantity: '',
        unitQuantity: '',
        unitCost: '',
      };
    case 'Quote':
      return { type, quote: '' };
    case 'Estimate':
      return { type, estimate: '' };
    default:
      return { type };
  }
};

/**
 * Convert a template object to a flat object indexed by the path of the element
 * @param template
 * @returns {{}}
 */
function templateAsFlatObject(template) {
  const obj = {};
  (template.children || []).forEach((e) => flattenTemplateTree(e, undefined, obj));
  return obj;
}

function flattenTemplateTree(elem, prefix, obj) {
  const path = prefix ? `${prefix}.${elem.id}` : elem.id;
  Vue.set(obj, path, elem);
  (elem.children || []).forEach((e) => flattenTemplateTree(e, path, obj));
}

function opportunityBasedUnit(opportunityType) {
  if (!opportunityType) return null;
  switch (opportunityType) {
    case 'Building: Non-IGA':
      return 'sf';
    case 'Building: IGA':
      return 'sf';
    case 'Streetlight':
      return 'heads';
    case 'Solar EPC':
      return 'W-DC';
    case 'Solar EPC w/ Energy Storage':
      return 'W-DC';
    case 'Energy Storage':
      return 'MWh';
    case 'Microgrid':
      return 'W-DC';
    case 'Solar: Carport':
      return 'W-DC';
    case 'Solar: Ground Mount':
      return 'W-DC';
    case 'Solar: Rooftop':
      return 'W-DC';
    case 'Solar: Carport w/ Energy Storage':
      return 'W-DC';
    case 'Solar: Ground Mount w/ Energy Storage':
      return 'W-DC';
    case 'Solar: Rooftop w/ Energy Storage':
      return 'W-DC';
    case 'Install: Lighting':
      return 'sf';
    case 'Install: Solar':
      return 'W-DC';
    case 'Install: Microgrid':
      return 'W-DC';
    case 'Install: Off-Grid Solar':
      return 'W-DC';
    case 'RFP: Lighting':
      return 'sf';
    case 'RFP: Solar':
      return 'W-DC';
    case 'RFP: Energy Storage':
      return 'MWh';
    case 'RFP: Solar + Energy Storage':
      return 'W-DC';
    case 'RFP: Microgrid':
      return 'W-DC';
    case 'RFP: EV Charging':
      return 'stations';
    case 'RFP: Electrical Contracting':
      return '$';
  }
  return 'Unknown opportunity type!';
}

function recursiveCalculateEstimate(obj, elem, prefix, projectFinanceDetails, projectFinance, rateTable) {
  const path = prefix ? `${prefix}.${elem.id}` : elem.id;
  let result = calculateCostTotal();
  if (projectFinanceDetails.hasOwnProperty(path)) {
    result = calculateCostTotal(projectFinanceDetails[path], projectFinance, rateTable);
    Vue.set(obj, path, result);
  } else if (elem.children?.length) {
    const lineTotals = elem.children.map((e) =>
      recursiveCalculateEstimate(obj, e, path, projectFinanceDetails, projectFinance, rateTable),
    );
    result = _mergeWith(result, ...lineTotals, totalsReducer);
    Vue.set(obj, elem.id === '' ? '__total' : path, result);
  }
  return result;
}

function getServiceTotalsForPath(
  path,
  scheduleForecast,
  estimationRecord,
  changeOrdersByPath,
  apRecordItemsByPath,
  apRecords,
  purchaseOrderItemsForecastingByPath,
  currentMonthName,
  monthsToForecast,
) {
  const details = estimationRecord?.__total?.[path] || {};
  const ogContractedBudget = details || {} ? { ...details } : null;
  const changeOrdersItems = changeOrdersByPath[path] || [];
  const COReducer = (type, approved) =>
    changeOrdersItems
      .filter((coi) => coi.type === type && (coi.status === 'Approved') === approved)
      .reduce((c, coi) => c + coi.details.GrossCost + coi.details.Taxes, false);
  const tempCOTotals = {
    pendingICO: COReducer('ICO', false),
    approvedICO: COReducer('ICO', true),
    pendingSCO: COReducer('SCO', false),
    approvedSCO: COReducer('SCO', true),
    pendingOCO: COReducer('OCO', false),
    approvedOCO: COReducer('OCO', true),
  };
  const changeOrdersTotals = {
    ...tempCOTotals,
    totalPending: [false, tempCOTotals.pendingICO, tempCOTotals.pendingSCO, tempCOTotals.pendingOCO].reduce(
      falseReducer,
    ),
    totalApproved: [false, tempCOTotals.approvedICO, tempCOTotals.approvedSCO, tempCOTotals.approvedOCO].reduce(
      falseReducer,
    ),
    COs: changeOrdersItems.map((coi) => coi.co),
  };
  const budgetModifications = _mergeWith(
    {},
    ...changeOrdersItems.filter((coi) => coi.status === 'Approved').map((coi) => coi.details),
    totalsReducer,
  );
  const approvedBudget = _mergeWith({}, ogContractedBudget, budgetModifications, totalsReducer);

  const actualBreakup =
    apRecordItemsByPath[path]?.reduce((acc, item) => {
      acc[item.status] = (acc[item.status] || 0) + item.amount;
      return acc;
    }, {}) ?? null;
  const actual = [
    actualBreakup?.['Approved to Pay'] ?? false,
    actualBreakup?.['Scheduled to Pay'] ?? false,
    actualBreakup?.['Paid'] ?? false,
  ].reduce(falseReducer, false);
  const pendingActual = [
    actualBreakup?.['Draft'] ?? false,
    actualBreakup?.['Submitted'] ?? false,
    actualBreakup?.['Rejected'] ?? false,
    actualBreakup?.['Hold'] ?? false,
  ].reduce(falseReducer, false);

  const currentMonth = moment(currentMonthName, 'MMM YYYY').format('YYYY-MM');

  const approvedActualByMonth = _mergeWith(
    {},
    ...(apRecordItemsByPath?.[path] || [])
      .filter((item) => ['Approved to Pay', 'Scheduled to Pay', 'Paid'].includes(item.status))
      .map((item) => {
        const apRecord = apRecords[item.apRecord.id];
        const month =
          item.status === 'Paid'
            ? moment(apRecord.paidDate).format('YYYY-MM')
            : item.status === 'Scheduled to Pay'
              ? moment(apRecord.scheduledPaymentDate).format('YYYY-MM')
              : apRecord.transactionType === 'Expense'
                ? moment(apRecord.receiptDate).add(20, 'd').format('YYYY-MM')
                : moment(apRecord.receiptDate).format('YYYY-MM');
        return {
          [month]: item.amount,
        };
      }),
    totalsReducer,
  );

  const committed = _mergeWith(
    {},
    ...(purchaseOrderItemsForecastingByPath?.[path] || [])
      .map((item) => item.values)
      .map((dailyForecast) =>
        Object.entries(dailyForecast).reduce((acc, [k, v]) => {
          const month = moment(k, 'YYYY-MM-DD').format('YYYY-MM');
          acc[month] = (acc[month] || 0) + v;
          return acc;
        }, {}),
      ),
    totalsReducer,
  );
  const forecastingDetails = scheduleForecast?.[path] ?? {};
  const forecastingMonths = monthsToForecast.reduce((c, month) => {
    c[month] = ((forecastingDetails || {})?.[month] || 0) + (committed?.[month] || 0);
    return c;
  }, {});
  const forecastingTotal = Object.values(forecastingMonths).reduce((acc, val) => acc + val, false);
  const nonCommittedActualTotal = (apRecordItemsByPath?.[path] || [])
    .filter((item) => ['Approved to Pay', 'Scheduled to Pay', 'Paid'].includes(item.status) && !item.committed)
    .map((item) => item.amount)
    .reduce((acc, val) => acc + val, false);
  const totalCommitted = Object.values(committed).reduce((acc, val) => acc + val, false);
  const planned = forecastingDetails || {};
  const totalPlanned = Object.values(planned).reduce(
    (acc, val) => acc + val,
    false
  );
  const anticipatedTotal =
    totalCommitted + Math.max(nonCommittedActualTotal, totalPlanned);
  const budgetToExpectedVariance =
    anticipatedTotal -
    (approvedBudget?.GrossCost ?? 0) -
    (approvedBudget?.Taxes ?? 0);
  const baseCommitted = _keyBy(
    purchaseOrderItemsForecastingByPath?.[path] || [],
    e => `${e.po.poNumber}#${e.index}`
  );
  const baseActual = _groupBy(
    (apRecordItemsByPath?.[path] || []).filter((l) => l.committed),
    (e) => `${e.associatedPO.poNumber}#${e.index}`,
  );
  const remainingCommitted = _mergeWith(
    {},
    ...Object.entries(baseCommitted).map(([k, v]) => {
      const forecast = Object.entries(v.values).reduce((acc, [k, v]) => {
        const month = moment(k, 'YYYY-MM-DD').format('YYYY-MM');
        acc[month] = (acc[month] || 0) + v;
        return acc;
      }, {});
      const relatedActual = baseActual[k] || [];
      const months = Object.keys(forecast).sort();
      relatedActual.forEach((actual) => {
        let amount = actual.amount;
        months.forEach((month) => {
          if (amount > 0) {
            const forecasted = forecast[month];
            if (forecasted > amount) {
              forecast[month] = forecasted - amount;
              amount = 0;
            } else {
              forecast[month] = 0;
              amount -= forecasted;
            }
          }
        });
      });
      return forecast;
    }),
    totalsReducer
  );
  const totalRemainingCommitted = Object.entries(remainingCommitted).reduce(
    (acc, [_k, v]) => {
      acc += +v;
      return acc;
    },
    0
  );

  const futureReducer = (obj) =>
    Object.entries(obj)
      .filter(([month, _]) => month > currentMonth)
      .map(([_, value]) => value)
      .reduce((acc, val) => acc + val, false);

  return {
    ogContractedBudget: ogContractedBudget,
    budgetModifications: budgetModifications,
    changeOrders: changeOrdersTotals,
    approvedBudget: approvedBudget,

    actualBreakup: actualBreakup,
    actual: actual,
    pendingActual: pendingActual,
    forecastingMonths: forecastingMonths,
    forecastingTotal: forecastingTotal,
    anticipatedTotal: totalRemainingCommitted + totalPlanned,
    currentMonth: {
      forecast: forecastingMonths?.[currentMonth] ?? false,
      committed: remainingCommitted?.[currentMonth] ?? false,
      actual: approvedActualByMonth?.[currentMonth] ?? false,
      planned: planned?.[currentMonth] ?? false,
    },
    future: {
      forecast: Object.entries(forecastingMonths)
        .filter(([month, _]) => month > currentMonth)
        .map(([_, value]) => value)
        .reduce((acc, val) => acc + val, false),
      committed: futureReducer(remainingCommitted),
      actual: futureReducer(approvedActualByMonth),
      planned: futureReducer(planned),
    },

    budgetToExpectedVariance: budgetToExpectedVariance,
  };
}

export {
  falseReducer,
  falseNArrayMerger,
  totalsReducer,
  calculateGC,
  calculateLBOP,
  calculateCostTotal,
  costFormObject,
  calculateProfit,
  templateAsFlatObject,
  flattenTemplateTree,
  opportunityBasedUnit,
  recursiveCalculateEstimate,
  getServiceTotalsForPath,
};
