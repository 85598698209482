import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDJiS5jjlautUAwwW5tZKy1mb33TVvhDag',
  authDomain: 'light-edison.firebaseapp.com',
  databaseURL: 'https://light-edison.firebaseio.com',
  projectId: 'light-edison',
  storageBucket: 'light-edison.appspot.com',
  messagingSenderId: '476956669460',
};

export function initFirebase() {
  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();

  window.firebase = { app: firebaseApp, auth, db, storage };
}
