<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatDate(date)"
        dense
        :dark="dark"
        :flat="flat"
        :single-line="singleLine"
        :solo="solo"
        :prepend-icon="prependIcon"
        :reverse="reverse"
        :outlined="outlined"
        :hide-details="hideDetails"
        :label="label"
        :error-messages="errors"
        :error-count="errors?.length || 0"
        :rules="rules"
        readonly
        v-bind="attrs"
        v-on="on"
        :class="inputClass"
        :clearable="clearable"
        @click:clear="$emit('change', null)"
      >
        <template v-slot:append-outer>
          <slot name="append-outer"></slot>
        </template>
        <template v-slot:label>
          <slot name="label"
            >{{ label }}
            <label v-if="required" class="red--text">*</label>
          </slot>
        </template>
        <template v-slot:append>
          <slot name="append"></slot>
        </template>
      </v-text-field>
    </template>

    <v-date-picker
      no-title
      :disabled="readonly"
      :value="pickerDate"
      :type="type"
      :allowed-dates="allowedDates"
      :min="min"
      @input="
        $emit('change', setDate($event));
        dateMenu = false;
      "
    ></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import moment from 'moment';

export default {
  model: {
    prop: 'date',
    event: 'change',
  },
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    solo: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    date: {
      type: [String, Date],
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    prependIcon: {
      type: String,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [String, Boolean],
      default: false,
    },
    inputClass: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    displayFormat: {
      type: String,
      default: 'M/D/YY',
    },
    type: {
      type: String,
      default: 'date',
    },
    allowedDates: {
      type: Function,
    },
    min: {
      type: String,
    },
  },
  data: () => ({
    dateMenu: false,
  }),
  computed: {
    pickerDate() {
      if (this.date) {
        const date = new Date(this.date);
        return date.toISOString().substr(0, 10);
      }
    },
  },
  methods: {
    /**
     * Setting the format for date field
     * @param  {string} event Vuetify's default format on datepicker (YYYY-MM-DD)
     */
    setDate: (event) => event || null,

    formatDate(date) {
      return date ? moment(date).format(this.displayFormat) : null;
    },
  },
};
</script>

<style></style>
