import RouteSOP from '@/core/entities/RouteSOP';
import RouteSOPsRepository from '@/core/interfaces/route-sops/RouteSOPRepository';
import * as FirestoreService from '@/services/firebase/FirestoreService.js';
import { SOPQueryInterface } from '@/core/use-cases/sops/interfaces/sopQuery';

const COLLECTION_NAME = 'routeSOPs';

export default class RouteSOPsRepositoryFirestoreImpl implements RouteSOPsRepository {
  addRouteSOP(routeSOP: RouteSOP): Promise<RouteSOP> {
    return FirestoreService.saveRecord({
      collectionName: COLLECTION_NAME,
      data: routeSOP,
    } as any)
      .then((docRef) => {
        return {
          ...routeSOP,
          id: docRef.id,
        };
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  getRouteSOPs(query: SOPQueryInterface): Promise<RouteSOP[]> {
    const filters = query.filters.map(({ field, operator, value }) => [field, operator, value]);
    return FirestoreService.getRecords({
      collectionName: COLLECTION_NAME,
      whereStatements: filters,
    })
      .then((records) => {
        return records;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async deleteRouteSOP(id: string): Promise<RouteSOP> {
    try {
      const record = await FirestoreService.getRecord({
        collectionName: COLLECTION_NAME,
        id,
      });
      if (record.id) {
        await FirestoreService.deleteRecord({
          collectionName: COLLECTION_NAME,
          id,
        } as any); // TODO: Check is there's a better way to type this
        return record;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}
