import moment from 'moment';
import {
  opportunityBasedUnit,
  templateAsFlatObject,
  totalsReducer,
} from '@/components/projectFinance/ProjectFinanceHelper';
import _groupBy from 'lodash/groupBy';
import Vue from 'vue';
import firebase from 'firebase';
import router from '@/router';
import { padNumber, systemFields } from '@/services/utils/Utils';
import _escape from 'lodash/escape';
import _uniqBy from 'lodash/uniqBy';
import { getPortfolio, getPortfolioOpportunities } from '@/components/portfolios/PortfolioHelper';
import _mergeWith from 'lodash/mergeWith';
import _uniq from 'lodash/uniq';
import _zip from 'lodash/zip';
import _zipObject from 'lodash/zipObject';
import { isDateFuture } from '@/services/utils/Utils';

const collapsedTemplate = {
  budgetGroup: true,
  ogContracted: true,
  changeOrders: true,
  approvedBudget: true,

  forecastingGroup: true,
  forecastingMonths: true,
  currentMonth: true,
  future: true,

  actualGroup: true,
  actuals: true,
  pActuals: true,
};
const filtersTemplate = {
  search: '',
};

// const accountingRecordTemplate = {
//   minMonth: moment().format("MMM YYYY"),
//   currentMonth: moment().format("MMM YYYY"),
//   leastMaxMonth: moment().format("MMM YYYY"),
//   maxMonth: moment().format("MMM YYYY"),
// };

const state = {
  opportunityID: null,
  opportunity: null,
  portfolioID: null,
  portfolio: null,
  template: {},
  estimationRecord: {},
  projectFinanceServicesDetails: {},
  portfolioProjectFinanceServicesDetails: {},
  scheduleForecast: {},
  portfolioScheduleForecast: {},
  collapsed: { ...collapsedTemplate },
  subs: [],
  showChildrenInSearch: false,
  showOnlyNonEmpty: true,
  filters: { ...filtersTemplate },

  // accountingRecord: {...accountingRecordTemplate},
  // accountingDetails: {},
  changeOrders: {},
  purchaseOrders: {},
  apRecords: {},
  rateTable: {},
  projectRoster: {},

  sopMapping: null,
  sops: {},
};

const getters = {
  isPortfolio: ({ portfolioID }) => !!portfolioID,
  currentMonthName: () =>
    moment().date() < 15 ? moment().subtract(1, 'month').format('MMM YYYY') : moment().format('MMM YYYY'),
  templateAsFlatObject: ({ template }) => templateAsFlatObject(template),
  opportunityBasedUnit: ({ opportunity }) =>
    opportunityBasedUnit(opportunity?.opportunityType),
  changeOrders: ({ changeOrders }) => changeOrders,
  changeOrdersByPath: ({ changeOrders }) => {
    return _groupBy(
      Object.values(changeOrders)
        .filter((co) => co.status !== 'Rejected')
        .flatMap((co) =>
          co.items.map((coi) => {
            const { GrossCost = 0, Taxes = 0, NetCost = 0 } = coi;
            return {
              details: { GrossCost, Taxes, NetCost },
              path: coi.projectFinanceRow.path,
              status: co.status,
              type: co.type,
              co: {
                id: co.id,
                apNumber: `CO-${padNumber(co.recordNumber, 4)}`,
              },
            };
          }),
        ),
      'path'
    );
  },
  getPathChangeOrders:
    ({ changeOrders }) =>
    path => {
      return Object.values(changeOrders)
        .filter(
          co =>
            co.status !== "Rejected" &&
            co.items.some(item => item.projectFinanceRow.path.startsWith(path))
        )
        .flatMap(co =>
          co.items.map(coi => {
            return {
              amounts: [coi.GrossCost + coi.NetCost + coi.Taxes],
              date: co.createdDate,
              records: `CO-${padNumber(co.recordNumber, 4)}`,
              type: 'changeOrder',
              id: co.id,
            };
          })
        );
    },
  // selfApprovedBudget: ({projectFinance, projectFinanceDetails}, {templateAsFlatObject, changeOrdersByPath}) => {
  //   const paths = _union(Object.keys(projectFinanceDetails), Object.keys(changeOrdersByPath));
  //   return paths.reduce((c, path) => {
  //     let currentValue = calculateCostTotal(projectFinanceDetails[path], projectFinance);
  //     const approvedCOs = (changeOrdersByPath[path] || []).filter(co => co.approved);
  //     approvedCOs.sort((a, b) => a.approvedDate > b.approvedDate);
  //     approvedCOs.forEach(co => {
  //       const coCostTotals = calculateCostTotal(co, projectFinance);
  //       currentValue = co.changeType === "Update" ? _mergeWith(currentValue, coCostTotals, falseNArrayMerger) : coCostTotals;
  //     });
  //     c[path] = currentValue;
  //     return c;
  //   }, {});
  // },
  estimationRecord: ({ estimationRecord }) => {
    return {
      __total: _mergeWith({}, ...Object.values(estimationRecord).map(({ __total = {} }) => __total), totalsReducer),
    };
  },
  projectFinanceServicesDetails: ({
    portfolioID,
    projectFinanceServicesDetails,
    portfolioProjectFinanceServicesDetails,
  }) => {
    if (portfolioID) {
      return _mergeWith({}, ...Object.values(portfolioProjectFinanceServicesDetails), totalsReducer);
    } else return projectFinanceServicesDetails;
  },
  approvedBudget: ({ portfolioID, opportunityID, estimationRecord, changeOrders }) => {
    const oppties = portfolioID
      ? _uniq([
          ...Object.keys(estimationRecord),
          ...Object.values(changeOrders).map(
            (co) => co.opportunity?.id ?? co.opportunityID, // old design had opportunityID
          ),
        ])
      : [opportunityID];

    return oppties.reduce((c, opportunityID) => {
      const baseTotals = Object.entries(estimationRecord[opportunityID]?.__total ?? {})
        .map(([path, totals]) => [path, totals.GrossCost + totals.Taxes])
        .reduce((a, [path, total]) => {
          a[path] = total;
          return a;
        }, {});

      const changeOrdersByPath = _groupBy(
        Object.values(changeOrders)
          .filter((co) => (co.opportunity?.id ?? co.opportunityID) === opportunityID)
          .filter((co) => co.status === 'Approved')
          .flatMap((co) =>
            co.items.map((coi) => {
              const { GrossCost = 0, Taxes = 0 } = coi;
              return {
                total: GrossCost + Taxes,
                path: coi.projectFinanceRow.path,
              };
            }),
          ),
        'path',
      );

      const paths = _uniq([...Object.keys(baseTotals), ...Object.keys(changeOrdersByPath)]);

      c[opportunityID] = paths.reduce((a, path) => {
        a[path] = (baseTotals[path] || 0) + (changeOrdersByPath[path] || []).reduce((a, b) => a + b.total, 0);
        return a;
      }, {});

      return c;
    }, {});
  },
  scheduleForecast: (
    { portfolioID, opportunityID, scheduleForecast, portfolioScheduleForecast },
    { approvedBudget },
  ) => {
    const scheduleForecastTransformer = (milestone, approvedBudget) => {
      const { start_date, budget } = milestone;
      const multiLineBudget = budget instanceof Array ? budget : [budget];
      return multiLineBudget.map((budget) => {
        const path = budget.path;
        const month = moment(start_date).format('YYYY-MM');
        const value = budget.type === 'amount' ? budget.value : (budget.value * approvedBudget[path]) / 100;
        return { [path]: { [month]: value } };
      });
    };
    if (portfolioID) {
      return _mergeWith(
        {},
        ...Object.entries(portfolioScheduleForecast).map(([opportunityID, forecast]) => {
          return _mergeWith(
            {},
            ...Object.values(forecast).flatMap((milestone) =>
              scheduleForecastTransformer(milestone, approvedBudget[opportunityID]),
            ),
            totalsReducer,
          );
        }),
        totalsReducer,
      );
    } else {
      return _mergeWith(
        {},
        ...Object.values(scheduleForecast).flatMap((milestone) =>
          scheduleForecastTransformer(milestone, approvedBudget[opportunityID]),
        ),
        totalsReducer,
      );
    }
  },

  weeklyScheduleForecast: (
    { portfolioID, opportunityID, scheduleForecast, portfolioScheduleForecast },
    { approvedBudget },
  ) => {
    const getWeekFromDate = (inputDate) => {
      const parts = inputDate.split('-');
      const date = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
      date.setUTCDate(date.getUTCDate() - date.getUTCDay());
      const week = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
      return week;
    };

    const scheduleForecastTransformer = (milestone, approvedBudget) => {
      const { start_date, budget } = milestone;
      const multiLineBudget = budget instanceof Array ? budget : [budget];
      return multiLineBudget.map((budget) => {
        const path = budget.path;
        const week = getWeekFromDate(start_date);
        const value = budget.type === 'amount' ? budget.value : (budget.value * approvedBudget[path]) / 100;
        return { [path]: { [week]: value } };
      });
    };
    if (portfolioID) {
      return _mergeWith(
        {},
        ...Object.entries(portfolioScheduleForecast).map(([opportunityID, forecast]) => {
          return _mergeWith(
            {},
            ...Object.values(forecast).flatMap((milestone) =>
              scheduleForecastTransformer(milestone, approvedBudget[opportunityID]),
            ),
            totalsReducer,
          );
        }),
        totalsReducer,
      );
    } else {
      return _mergeWith(
        {},
        ...Object.values(scheduleForecast).flatMap((milestone) =>
          scheduleForecastTransformer(milestone, approvedBudget[opportunityID]),
        ),
        totalsReducer,
      );
    }
  },

  dailyScheduleForecast: (
    { portfolioID, opportunityID, scheduleForecast, portfolioScheduleForecast },
    { approvedBudget },
  ) => {
    const getFormattedDate = (inputDate) => {
      const parts = inputDate.split('-');
      const date = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
      return date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
    };

    const scheduleForecastTransformer = (milestone, approvedBudget) => {
      const { start_date, budget } = milestone;
      const multiLineBudget = budget instanceof Array ? budget : [budget];
      return multiLineBudget.map((budget) => {
        const path = budget.path;
        const day = getFormattedDate(start_date);
        const value = budget.type === 'amount' ? budget.value : (budget.value * approvedBudget[path]) / 100;
        return { [path]: { [day]: value } };
      });
    };
    if (portfolioID) {
      return _mergeWith(
        {},
        ...Object.entries(portfolioScheduleForecast).map(([opportunityID, forecast]) => {
          return _mergeWith(
            {},
            ...Object.values(forecast).flatMap((milestone) =>
              scheduleForecastTransformer(milestone, approvedBudget[opportunityID]),
            ),
            totalsReducer,
          );
        }),
        totalsReducer,
      );
    } else {
      return _mergeWith(
        {},
        ...Object.values(scheduleForecast).flatMap((milestone) =>
          scheduleForecastTransformer(milestone, approvedBudget[opportunityID]),
        ),
        totalsReducer,
      );
    }
  },

  purchaseOrderItemsByPath: ({ purchaseOrders }) => {
    const nonArchived = Object.values(purchaseOrders).filter((po) => po.archived !== 'Yes');
    const items = nonArchived.flatMap((po) => {
      const isService = po.isService || false;
      return (isService ? po.items : po.products).map((line) => ({
        amount: isService ? line.price : line.totalPrice,
        projectFinanceRow: line.projectFinanceRow?.path || '__empty__',
        po: {
          id: po.id,
          poNumber: po.poNumber,
        },
      }));
    });
    return _groupBy(items, 'projectFinanceRow');
  },
  purchaseOrderItemsForecastingByPath: ({ purchaseOrders }) => {
    const nonArchivedForecasted = Object.values(purchaseOrders).filter((po) => po.archived !== 'Yes' && po.forecasting);
    const items = nonArchivedForecasted.flatMap((po) => {
      const isService = po.isService || false;
      const lines = isService ? po.items : po.products;
      if (po.forecasting.version === 2) {
        const forecastByItem = _zip(...po.forecasting.lines.map((x) => x.items)).map((lineItem) =>
          _zipObject(
            po.forecasting.lines.map((x) => x.date),
            lineItem.map((entry) => entry.values.amount || 0),
          ),
        );
        return forecastByItem.map((item, itemIndex) => {
          return {
            values: item,
            projectFinanceRow: lines[itemIndex].projectFinanceRow?.path || '__empty__',
            index: itemIndex,
            po: {
              id: po.id,
              poNumber: po.poNumber,
            },
          };
        });
      } else {
        return po.forecasting.items.map((line, itemIndex) => ({
          values: Object.keys(line.values).reduce((c, month) => {
            c[`${month}-15`] = line.values[month].amount || 0;
            return c;
          }, {}),
          projectFinanceRow: lines[itemIndex].projectFinanceRow?.path || '__empty__',
          index: itemIndex,
          po: {
            id: po.id,
            poNumber: po.poNumber,
          },
        }));
      }
    });
    return _groupBy(items, 'projectFinanceRow');
  },
  apRecordItemsByPath: ({ apRecords }) => {
    const items = Object.values(apRecords).flatMap((record) => {
      return [
        ...record.committedLines.map((r, i) => ({
          ...r,
          committed: true,
          index: i,
        })),
        ...record.nonCommittedLines.map((r) => ({ ...r, committed: false })),
      ].map((line) => ({
        amount: line.amount,
        projectFinanceRow: line.projectFinanceRow?.path || '__empty__',
        status: record.status,
        apRecord: {
          id: record.id,
          apNumber: `AP-${padNumber(record.recordNumber, 4)}`,
        },
        committed: line.committed,
        index: line.index ?? null,
        associatedPO: line.committed ? record.associatedPO : null,
      }));
    });
    return _groupBy(items, 'projectFinanceRow');
  },

  forecastingWeeks: (_, { forecastingMonths }) => {
    let weeksStartDates = [];
    let nextWeekStartDate;
    //start with the months, and then adjust out from there
    const months = forecastingMonths;
    const firstMonthParts = months[0].split('-');
    const firstDayOfFirstMonth = new Date(Date.UTC(firstMonthParts[0], firstMonthParts[1] - 1, 1));

    const lastMonth = months[months.length - 1];
    const lastMonthParts = lastMonth.split('-');

    const firstDayOfLastMonth = new Date(Date.UTC(lastMonthParts[0], lastMonthParts[1] - 1, 1));
    const lastDayOfLastMonth = new Date();
    lastDayOfLastMonth.setUTCFullYear(firstDayOfLastMonth.getUTCFullYear());
    lastDayOfLastMonth.setUTCMonth(firstDayOfLastMonth.getUTCMonth() + 1);
    lastDayOfLastMonth.setUTCDate(0);

    const dayOfWeek = firstDayOfFirstMonth.getUTCDay();
    nextWeekStartDate = new Date();
    nextWeekStartDate.setUTCFullYear(firstDayOfFirstMonth.getUTCFullYear());
    nextWeekStartDate.setUTCMonth(firstDayOfFirstMonth.getUTCMonth());
    nextWeekStartDate.setUTCDate(firstDayOfFirstMonth.getUTCDate() - dayOfWeek);
    weeksStartDates.push(
      nextWeekStartDate.getUTCFullYear() +
        '-' +
        (nextWeekStartDate.getUTCMonth() + 1) +
        '-' +
        nextWeekStartDate.getUTCDate(),
    );

    nextWeekStartDate.setUTCDate(nextWeekStartDate.getUTCDate() + 7);
    while (nextWeekStartDate <= lastDayOfLastMonth) {
      weeksStartDates.push(
        nextWeekStartDate.getUTCFullYear() +
          '-' +
          (nextWeekStartDate.getUTCMonth() + 1) +
          '-' +
          nextWeekStartDate.getUTCDate(),
      );
      nextWeekStartDate.setUTCDate(nextWeekStartDate.getUTCDate() + 7);
    }
    return weeksStartDates;
  },

  forecastingDays: (_, { forecastingMonths }) => {
    let forecastingDays = [];
    //start with the months, and then adjust out from there
    const months = forecastingMonths;
    const firstMonthParts = months[0].split('-');
    const dayToAdd = new Date(Date.UTC(firstMonthParts[0], firstMonthParts[1] - 1, 1));

    const lastMonth = months[months.length - 1];
    const lastMonthParts = lastMonth.split('-');

    const firstDayOfLastMonth = new Date(Date.UTC(lastMonthParts[0], lastMonthParts[1] - 1, 1));
    const lastDayOfLastMonth = new Date();
    lastDayOfLastMonth.setUTCFullYear(firstDayOfLastMonth.getUTCFullYear());
    lastDayOfLastMonth.setUTCMonth(firstDayOfLastMonth.getUTCMonth() + 1);
    lastDayOfLastMonth.setUTCDate(0);

    forecastingDays.push(dayToAdd.getUTCFullYear() + '-' + (dayToAdd.getUTCMonth() + 1) + '-' + dayToAdd.getUTCDate());

    dayToAdd.setUTCDate(dayToAdd.getUTCDate() + 1);
    while (dayToAdd <= lastDayOfLastMonth) {
      forecastingDays.push(
        dayToAdd.getUTCFullYear() + '-' + (dayToAdd.getUTCMonth() + 1) + '-' + dayToAdd.getUTCDate(),
      );
      dayToAdd.setUTCDate(dayToAdd.getUTCDate() + 1);
    }
    return forecastingDays;
  },

  forecastingMonths: (_, { projectFinanceProperties, highestMinMonth, leastMaxMonth }) => {
    let minMonth;
    if (projectFinanceProperties?.minMonth) {
      minMonth =
        highestMinMonth && highestMinMonth < projectFinanceProperties.minMonth
          ? moment(highestMinMonth, 'YYYY-MM')
          : moment(projectFinanceProperties.minMonth, 'YYYY-MM');
    } else {
      minMonth = highestMinMonth ? moment(highestMinMonth, 'YYYY-MM') : moment().startOf('month');
    }
    let maxMonth;
    if (projectFinanceProperties?.maxMonth) {
      maxMonth =
        leastMaxMonth && leastMaxMonth > projectFinanceProperties.maxMonth
          ? moment(leastMaxMonth, 'YYYY-MM')
          : moment(projectFinanceProperties.maxMonth, 'YYYY-MM');
    } else {
      maxMonth = leastMaxMonth ? moment(leastMaxMonth, 'YYYY-MM') : moment().startOf('month');
    }
    const months = [];
    let currentMonth = minMonth.clone();
    while (currentMonth.isSameOrBefore(maxMonth)) {
      months.push(currentMonth.format('YYYY-MM'));
      currentMonth.add(1, 'month');
    }
    return months;
  },
  projectFinanceProperties: ({ projectFinanceServicesDetails }) => projectFinanceServicesDetails?.properties ?? null,
  highestMinMonth: (_, { scheduleForecast }) =>
    Object.values(scheduleForecast)
      .flatMap((f) => Object.keys(f))
      .reduce((c, v) => (c ? (c < v ? c : v) : v), null),
  leastMaxMonth: (_, { scheduleForecast }) =>
    Object.values(scheduleForecast)
      .flatMap((f) => Object.keys(f))
      .reduce((c, v) => (c ? (c > v ? c : v) : v), null),
  checks: (state, getters) => {
    const notArchivedPOs = Object.values(state.purchaseOrders).filter((po) => po.archived !== 'Yes');
    const apRecords = Object.values(state.apRecords).filter((ap) => ap.status !== 'Rejected');
    const isPOForcastedToFuture = (po) =>
      po.forecasting && (!po.forecasting.lines || po.forecasting.lines.every((line) => isDateFuture(line.date)));
    const isAPRecordReducingTheCommitForecast = (ap) => {
      const poNumber = ap.associatedPO?.poNumber;
      if (!poNumber || (ap.status !== 'Paid' && ap.status !== 'Scheduled to Pay' && ap.status !== 'Approved to Pay')) {
        return true;
      }
      const po = notArchivedPOs.find((po) => po.poNumber === poNumber);
      const isReducing = po?.apReductions?.some((reduction) => reduction.apReduction.ApRecord.id === ap.id);
      return !!isReducing;
    };

    return {
      allPOsItemsAreAssigned: {
        passed: !getters.purchaseOrderItemsByPath.hasOwnProperty('__empty__'),
        items: _uniqBy(
          (getters.purchaseOrderItemsByPath.__empty__ ?? []).map(({ po }) => po),
          'id',
        ),
      },
      allPOsItemsAreAssignedToLeafNodes: { passed: true, items: [] },
      allPOsAreForecasted: {
        passed: notArchivedPOs.every((po) => po.forecasting),
        items: notArchivedPOs.filter((po) => !po.forecasting),
      },
      allPOsAreForecastedToTheFuture: {
        passed: notArchivedPOs.every((po) => isPOForcastedToFuture(po)),
        items: notArchivedPOs.filter((po) => !isPOForcastedToFuture(po)),
      },
      allAPRecordsAreForecasted: {
        passed: apRecords.every((ap) => isAPRecordReducingTheCommitForecast(ap)),
        items: apRecords.filter((ap) => !isAPRecordReducingTheCommitForecast(ap)),
      },
      allAPItemsAreAssignedToLeafNodes: {
        passed: Object.keys(getters.apRecordItemsByPath).every(
          (path) => !getters.templateAsFlatObject[path]?.children?.length,
        ),
        items: _uniqBy(
          Object.entries(getters.apRecordItemsByPath)
            .filter(([path]) => !!getters.templateAsFlatObject[path]?.children?.length)
            .flatMap(([_, lines]) => lines.map((line) => line.apRecord)),
          'id',
        ),
      },
      allPOItemsAreBudgeted: { passed: true, items: [] },
      allAPItemsAreBudgeted: { passed: true, items: [] },
      noSubmittedAPs: {
        passed: apRecords.every((record) => record.status !== 'Submitted'),
        items: apRecords.filter((record) => record.status === 'Submitted'),
      },
      noOnHoldAPs: {
        passed: apRecords.every((record) => record.status !== 'Hold'),
        items: apRecords.filter((record) => record.status === 'Hold'),
      },
      forecastsWithinRange: { passed: true, items: [] },
    };
  },
};

const mutations = {
  unsub(state) {
    const subs = state.subs;
    state.subs = [];
    subs.forEach((unsub) => {
      if (unsub && typeof unsub === 'function') unsub();
    });
  },
  clearTemplate(state) {
    state.template = {};
  },
  clearRecordDetails(state) {
    state.opportunityID = null;
    state.opportunity = null;
    state.portfolioID = null;
    state.portfolio = null;
    state.estimationRecord = {};
    state.projectFinanceServicesDetails = {};
    state.portfolioProjectFinanceServicesDetails = {};
    state.scheduleForecast = {};
    state.portfolioScheduleForecast = {};
    state.changeOrders = {};
    state.purchaseOrders = {};
    state.apRecords = {};
    state.rateTable = null;
    state.projectRoster = null;
    state.sopMapping = null;
  },
  resetCollapsed(state) {
    Vue.set(state, 'collapsed', { ...collapsedTemplate });
  },
  // resetAccountingRecord(state) {
  //   Vue.set(state, 'accountingRecord', {...accountingRecordTemplate});
  //   // Vue.set(state, 'accountingDetails', {});
  // },
  sub(state, sub) {
    state.subs.push(sub);
  },
  setOpportunity(state, data) {
    Vue.set(state, 'opportunity', data);
  },
  setPortfolio(state, data) {
    Vue.set(state, 'portfolio', data);
  },
  setTemplate(state, data) {
    Vue.set(state, 'template', data);
  },
  setSOPRecord(state, { id, data }) {
    Vue.set(state.sops, id, data);
  },
  setSOPs(state, data) {
    Vue.set(state, 'sopMapping', data);
  },
  setEstimationRecord(state, { opportunityID, data }) {
    Vue.set(state.estimationRecord, opportunityID, data);
  },
  setServicesRecordDetails(state, { path, data }) {
    Vue.set(state.projectFinanceServicesDetails, path, data);
  },
  deleteServicesRecordDetail(state, path) {
    Vue.delete(state.projectFinanceServicesDetails, path);
  },
  setPortfolioServicesRecordDetails(state, { opportunityID, path, data }) {
    if (!state.portfolioProjectFinanceServicesDetails.hasOwnProperty(opportunityID))
      Vue.set(state.portfolioProjectFinanceServicesDetails, opportunityID, {});
    Vue.set(state.portfolioProjectFinanceServicesDetails[opportunityID], path, data);
  },
  deletePortfolioServicesRecordDetail(state, { opportunityID, path }) {
    if (state.portfolioProjectFinanceServicesDetails?.[opportunityID])
      Vue.delete(state.portfolioProjectFinanceServicesDetails[opportunityID], path);
  },
  setScheduleForecast(state, { id, data }) {
    Vue.set(state.scheduleForecast, id, data);
  },
  deleteScheduleForecast(state, id) {
    Vue.delete(state.scheduleForecast, id);
  },
  setPortfolioScheduleForecast(state, { opportunityID, id, data }) {
    if (!state.portfolioScheduleForecast.hasOwnProperty(opportunityID))
      Vue.set(state.portfolioScheduleForecast, opportunityID, {});
    Vue.set(state.portfolioScheduleForecast[opportunityID], id, data);
  },
  deletePortfolioScheduleForecast(state, { opportunityID, id }) {
    if (state.portfolioScheduleForecast?.[opportunityID])
      Vue.delete(state.portfolioScheduleForecast[opportunityID], id);
  },
  setChangeOrder(state, { id, data }) {
    Vue.set(state.changeOrders, id, data);
  },
  // setBulkChangeOrder(state, data) {
  //   Vue.set(state, "changeOrders", data);
  // },
  // deleteChangeOrder(state, id) {
  //   Vue.delete(state.changeOrders, id);
  // },
  setPurchaseOrder(state, { id, data }) {
    Vue.set(state.purchaseOrders, id, data);
  },
  setAPRecord(state, { id, data }) {
    Vue.set(state.apRecords, id, data);
  },
  selectVersion(state, { opportunityID, portfolioID }) {
    state.opportunityID = opportunityID;
    state.portfolioID = portfolioID;
  },
  toggleCollapsed(state, section) {
    state.collapsed[section] = !state.collapsed[section];
  },
  setShowChildrenInSearch(state, show) {
    state.showChildrenInSearch = show;
  },
  setShowOnlyNonEmpty(state, show) {
    state.showOnlyNonEmpty = show;
  },
  /*************** Actuals ***************/
  // setActualRecord(state, {id, data}) {
  //   Vue.set(state.accountingDetails, id, data);
  // },
  // setMinMonth(state, min) {
  //   state.accountingRecord.minMonth = min;
  // },
  // setLeastMaxMonth(state, max) {
  //   state.accountingRecord.leastMaxMonth = max;
  // },
  // setMaxMonth(state, max) {
  //   state.accountingRecord.maxMonth = max;
  // },
  resetFilters(state) {
    Vue.set(state, 'filters', { ...filtersTemplate });
  },
  setFilter(state, { key, value }) {
    state.filters[key] = value;
  },
  setRateTable(state, table) {
    state.rateTable = table;
  },
  setProjectRoster(state, table) {
    state.projectRoster = table;
  },
};

const actions = {
  initStore({ commit }, { opportunityID = null, portfolioID = null }) {
    commit('unsub');
    commit('resetCollapsed');
    commit('resetFilters');
    commit('clearTemplate');
    // commit('clearRecord');
    commit('clearRecordDetails');
    // commit('resetAccountingRecord');
    commit('selectVersion', { opportunityID, portfolioID });
    const firestore = firebase.firestore();

    const templateSubscription = firestore
      .collection('projectFinance')
      .doc('template')
      .onSnapshot((doc) => {
        if (doc.exists && !doc.metadata.hasPendingWrites) commit('setTemplate', doc.data());
      });
    commit('sub', templateSubscription);

    if (portfolioID) {
      getPortfolio(portfolioID)
        .then((portfolio) => {
          getPortfolioOpportunities(portfolio).then((oppties) => {
            Vue.set(portfolio, 'opportunities', oppties);
            commit('setPortfolio', portfolio);

            const supportsINQuery = oppties.length <= 10;
            if (supportsINQuery) {
              const opportunityIDs = oppties.map(({ opportunityID }) => opportunityID);

              const purchaseOrdersSub = firestore
                .collection('purchaseOrders')
                .where('opportunityID', 'in', opportunityIDs)
                .onSnapshot((querySnapshot) => {
                  querySnapshot.docChanges().forEach((change) => {
                    const id = change.doc.id;
                    const data = { id, ...change.doc.data() };
                    if (change.type !== 'removed') {
                      commit('setPurchaseOrder', { id, data });
                    }
                  });
                });
              commit('sub', purchaseOrdersSub);

              const apRecordsSub = firestore
                .collection('apRecords')
                .where('opportunity.id', 'in', opportunityIDs)
                .onSnapshot((querySnapshot) => {
                  querySnapshot.docChanges().forEach((change) => {
                    const id = change.doc.id;
                    const data = { id, ...change.doc.data() };
                    if (change.type !== 'removed') {
                      commit('setAPRecord', { id, data });
                    }
                  });
                });
              commit('sub', apRecordsSub);
            }

            oppties.forEach(({ opportunityID }) => {
              const opportunityPFDoc = firestore.collection('projectFinance').doc(opportunityID);

              const servicesCollectionReference = opportunityPFDoc.collection('services');

              const recordDoc = servicesCollectionReference.doc('serviceRecord');
              recordDoc.get().then((doc) => {
                if (doc.exists) {
                  commit('setEstimationRecord', {
                    opportunityID,
                    data: doc.data(),
                  });

                  const projectFinanceServicesSub = servicesCollectionReference.onSnapshot((querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                      // if (change.doc.metadata.hasPendingWrites) return;
                      if (change.doc.id === 'serviceRecord') return;
                      const data = change.doc.data();
                      if (change.type === 'removed') {
                        commit('deletePortfolioServicesRecordDetail', {
                          opportunityID,
                          path: data.path,
                        });
                      } else {
                        commit('setPortfolioServicesRecordDetails', {
                          opportunityID,
                          path: data.path,
                          data,
                        });
                      }
                    });
                  });
                  commit('sub', projectFinanceServicesSub);
                }
              });

              const changeOrdersSub = opportunityPFDoc.collection('changeOrders').onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                  const id = change.doc.id;
                  const data = change.doc.data();
                  if (change.type !== 'removed') {
                    commit('setChangeOrder', { id, data });
                  }
                });
              });
              commit('sub', changeOrdersSub);

              if (!supportsINQuery) {
                const purchaseOrdersSub = firestore
                  .collection('purchaseOrders')
                  .where('opportunityID', '==', opportunityID)
                  .onSnapshot((querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                      const id = change.doc.id;
                      const data = { id, ...change.doc.data() };
                      if (change.type !== 'removed') {
                        commit('setPurchaseOrder', { id, data });
                      }
                    });
                  });
                commit('sub', purchaseOrdersSub);

                const apRecordsSub = firestore
                  .collection('apRecords')
                  .where('opportunity.id', '==', opportunityID)
                  .onSnapshot((querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                      const id = change.doc.id;
                      const data = { id, ...change.doc.data() };
                      if (change.type !== 'removed') {
                        commit('setAPRecord', { id, data });
                      }
                    });
                  });
                commit('sub', apRecordsSub);

                const scheduleForecastSub = firestore
                  .collection('scheduling')
                  .doc(opportunityID)
                  .collection('versions')
                  .doc('default')
                  .collection('activities')
                  .where('recordType', '==', 'AP')
                  .onSnapshot((querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                      const id = change.doc.id;
                      const data = change.doc.data();
                      if (change.type === 'removed') {
                        commit('deleteScheduleForecast', id);
                      } else {
                        commit('setScheduleForecast', { id, data });
                      }
                    });
                  });
                commit('sub', scheduleForecastSub);
              }
            });
          });
        })
        .catch(() => {
          swal(
            {
              title: 'Error!',
              text: 'Portfolio not found!',
              type: 'error',
              closeOnConfirm: true,
            },
            () => {
              router.push({ name: 'PortfolioList' });
            },
          );
        });
    } else {
      firestore
        .collection('opportunities')
        .doc(opportunityID)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            swal(
              {
                title: 'Error!',
                text: 'Opportunity not found!',
                type: 'error',
                closeOnConfirm: true,
              },
              () => {
                router.push('/opportunities?where=pf');
              },
            );
          } else commit('setOpportunity', doc.data());
        });

      const opportunityPFDoc = firestore.collection('projectFinance').doc(opportunityID);

      const servicesCollectionReference = opportunityPFDoc.collection('services');

      const recordDoc = servicesCollectionReference.doc('serviceRecord');
      recordDoc.get().then((doc) => {
        if (!doc.exists) {
          swal(
            {
              title: 'Services Record not found!',
              text: 'The project finance services record is not created. You can still view commits and actuals if they exist but budget will remain empty. Do you want to continue without creating a record?',
              type: 'error',
              showCancelButton: true,
              confirmButtonClass: 'btn-danger',
              confirmButtonText: 'Yes, continue!',
              cancelButtonText: 'No, go back!',
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            (confirm) => {
              if (!confirm)
                router.push({
                  name: 'ProjectFinancePicker',
                  params: { opportunityID },
                });
            },
          );
        } else commit('setEstimationRecord', { opportunityID, data: doc.data() });
      });

      const projectFinanceServicesSub = servicesCollectionReference.onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          // if (change.doc.metadata.hasPendingWrites) return;
          if (change.doc.id === 'serviceRecord') return;
          const data = change.doc.data();
          if (change.type === 'removed') {
            commit('deleteServicesRecordDetail', data.path);
          } else {
            commit('setServicesRecordDetails', { path: data.path, data });
          }
        });
      });
      commit('sub', projectFinanceServicesSub);

      const changeOrdersSub = opportunityPFDoc.collection('changeOrders').onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const id = change.doc.id;
          const data = change.doc.data();
          if (change.type !== 'removed') {
            commit('setChangeOrder', { id, data });
          }
        });
      });
      commit('sub', changeOrdersSub);

      const purchaseOrdersSub = firestore
        .collection('purchaseOrders')
        .where('opportunityID', '==', opportunityID)
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            const id = change.doc.id;
            const data = { id, ...change.doc.data() };
            if (change.type !== 'removed') {
              commit('setPurchaseOrder', { id, data });
            }
          });
        });
      commit('sub', purchaseOrdersSub);

      const apRecordsSub = firestore
        .collection('apRecords')
        .where('opportunity.id', '==', opportunityID)
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            const id = change.doc.id;
            const data = { id, ...change.doc.data() };
            if (change.type !== 'removed') {
              commit('setAPRecord', { id, data });
            }
          });
        });
      commit('sub', apRecordsSub);

      const laborRatesCollection = opportunityPFDoc.collection('laborRates');

      const rateTableSub = laborRatesCollection.doc('servicesRates').onSnapshot((doc) => {
        if (doc.exists) commit('setRateTable', doc.data());
      });
      commit('sub', rateTableSub);

      const projectRosterSub = laborRatesCollection.doc('projectRoster').onSnapshot((doc) => {
        if (doc.exists) commit('setProjectRoster', doc.data());
      });
      commit('sub', projectRosterSub);

      const scheduleForecastSub = firestore
        .collection('scheduling')
        .doc(opportunityID)
        .collection('versions')
        .doc('default')
        .collection('activities')
        .where('recordType', '==', 'AP')
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            const id = change.doc.id;
            const data = change.doc.data();
            if (change.type === 'removed') {
              commit('deleteScheduleForecast', id);
            } else {
              commit('setScheduleForecast', { id, data });
            }
          });
        });
      commit('sub', scheduleForecastSub);
    }

    // const sopMapperSubscription = firestore
    //   .collection("projectFinance")
    //   .doc("SOPs")
    //   .onSnapshot(doc => {
    //     if (doc.exists) commit("setSOPs", doc.data());
    //   });
    // commit("sub", sopMapperSubscription);

    // const sopsSub = firestore.collection("sops").onSnapshot(querySnapshot => {
    //   querySnapshot.docChanges().forEach(change => {
    //     const data = change.doc.data();
    //     if (change.type !== "removed") {
    //       commit("setSOPRecord", {
    //         id: change.doc.id,
    //         data: { ...data, id: change.doc.id },
    //       });
    //     }
    //   });
    // });
    // commit("sub", sopsSub);

    // let projectFinanceDetailsFirstTime = true;
    // const detailsSub = versionDoc.collection('projectFinanceDetails').onSnapshot(querySnapshot => {
    //   const collectiveData = {};
    //   querySnapshot.docChanges().forEach(change => {
    //     const data = change.doc.data();
    //     if (change.type === "removed") {
    //       commit('deleteRecordDetail', data.path);
    //     } else {
    //       if (projectFinanceDetailsFirstTime) collectiveData[data.path] = data;
    //       else commit('setRecordDetail', {path: data.path, data});
    //     }
    //   });
    //   if (projectFinanceDetailsFirstTime) {
    //     projectFinanceDetailsFirstTime = false;
    //     commit('setBulkRecordDetail', collectiveData);
    //   }
    // });
    // commit('sub', detailsSub);
  },
  saveProjectFinanceServicesRecord({ state }, { path, obj }) {
    const firestore = firebase.firestore();
    const servicesCollectionReference = firestore
      .collection('projectFinance')
      .doc(state.opportunityID)
      .collection('services');
    const isUpdate = state.projectFinanceServicesDetails.hasOwnProperty(path);
    const logRef = servicesCollectionReference.doc('__log');
    const docRef = isUpdate
      ? servicesCollectionReference.doc(state.projectFinanceServicesDetails[path].projectFinanceServicesDetailID)
      : servicesCollectionReference.doc();
    const batch = firestore.batch();

    const now = moment().toISOString(false);
    const record = {
      ...obj,
      path,
      projectFinanceServicesDetailID: docRef.id,
      opportunityID: state.opportunityID,
      ...systemFields(isUpdate, firebase.auth().currentUser, now),
    };
    batch.set(docRef, record, { merge: true });

    const logElement = {
      createdBy: firebase.auth().currentUser.displayName,
      created: now,
      changeLog: Object.entries(obj)
        .map(([key, value]) => {
          let changelogRecord, createdDate, modifiedDate, createdBy, modifiedBy;
          if (value === null) {
            changelogRecord = null;
          } else {
            ({
               
              createdDate,
               
              modifiedDate,
               
              createdBy,
               
              modifiedBy,
              ...changelogRecord
            } = value);
          }
          return `set ${key} to <code>${_escape(JSON.stringify(changelogRecord))}</code>`;
        })
        .join('<br>'),
    };
    batch.set(
      logRef,
      {
        [path]: firebase.firestore.FieldValue.arrayUnion(logElement),
        path: 'log',
        projectFinanceServicesDetailID: '__log',
      },
      { merge: true },
    );
    return batch.commit() /*.then(() => {
      commit('mergeDetails', record);
      commit('addLog', logElement);
    })*/;
  },
  saveProjectFinanceChangeOrder({ state }, { id, obj }) {
    const firestore = firebase.firestore();
    const projectFinanceReference = firestore.collection('projectFinance').doc(state.opportunityID);
    const changeOrdersCollectionReference = projectFinanceReference.collection('changeOrders');
    const isUpdate = !!id && state.changeOrders.hasOwnProperty(id);
    const docRef = isUpdate ? changeOrdersCollectionReference.doc(id) : changeOrdersCollectionReference.doc();

    let changelogRecord, createdDate, modifiedDate, createdBy, modifiedBy, log;
    if (obj instanceof Object) {
      ({
         
        createdDate,
         
        modifiedDate,
         
        createdBy,
         
        modifiedBy,
         
        log,
        ...changelogRecord
      } = obj);
    } else {
      changelogRecord = obj;
    }

    const now = moment().toISOString(false);
    const logElement = {
      createdBy: firebase.auth().currentUser.displayName,
      created: now,
      changeLog: `${
        isUpdate ? `Update CO-${padNumber(state.changeOrders[id].recordNumber, 4)}` : 'Created a CO'
      } <code>${_escape(JSON.stringify(changelogRecord))}</code>`,
    };

    const metadataDocument = firestore.collection('global').doc('projectFinanceCO');
    return firestore.runTransaction(async (transaction) => {
      const record = {
        ...obj,
        id: docRef.id,
        opportunityID: state.opportunityID,
        ...systemFields(isUpdate, firebase.auth().currentUser, now),
        log: firebase.firestore.FieldValue.arrayUnion(logElement),
      };
      if (!isUpdate) {
        const recordNumber = await transaction
          .get(metadataDocument)
          .then((doc) => (doc.exists ? doc.get('count') + 1 : 1));
        await transaction.set(metadataDocument, { count: firebase.firestore.FieldValue.increment(1) }, { merge: true });
        record.recordNumber = recordNumber;
      }
      return transaction.set(docRef, record, { merge: true });
    });
  },
  setRateTableItem({ state }, { path, item }) {
    const firestore = firebase.firestore();

    const now = moment().toISOString(false);
    const record = {
      ...item,
      ...systemFields(state.rateTable?.items?.[path], firebase.auth().currentUser, now),
    };

    const {
       
      createdDate,
       
      modifiedDate,
       
      createdBy,
       
      modifiedBy,
      ...changelogRecord
    } = item;

    const logElement = {
      createdBy: firebase.auth().currentUser.displayName,
      created: now,
      changeLog: Object.entries(changelogRecord)
        .map(([key, value]) => {
          return `set ${key} to <code>${_escape(JSON.stringify(value))}</code>`;
        })
        .join('<br>'),
    };

    return firestore
      .collection('projectFinance')
      .doc(state.opportunityID)
      .collection('laborRates')
      .doc('servicesRates')
      .set(
        {
          items: {
            [path]: record,
          },
          log: firebase.firestore.FieldValue.arrayUnion(logElement),
          opportunityID: state.opportunityID,
        },
        { merge: true },
      );
  },
  setProjectRosterItem({ state }, { id, item }) {
    const firestore = firebase.firestore();

    const now = moment().toISOString(false);
    if (item) {
      const record = {
        ...item,
        ...systemFields(state.projectRoster?.items?.[id], firebase.auth().currentUser, now),
      };

      const {
         
        createdDate,
         
        modifiedDate,
         
        createdBy,
         
        modifiedBy,
        ...changelogRecord
      } = item;

      const logElement = {
        createdBy: firebase.auth().currentUser.displayName,
        created: now,
        changeLog: Object.entries(changelogRecord)
          .map(([key, value]) => {
            return `set ${key} to <code>${_escape(JSON.stringify(value))}</code>`;
          })
          .join('<br>'),
      };

      return firestore
        .collection('projectFinance')
        .doc(state.opportunityID)
        .collection('laborRates')
        .doc('projectRoster')
        .set(
          {
            items: {
              [id]: record,
            },
            log: firebase.firestore.FieldValue.arrayUnion(logElement),
            opportunityID: state.opportunityID,
          },
          { merge: true },
        );
    } else {
      const logElement = {
        createdBy: firebase.auth().currentUser.displayName,
        created: now,
        changeLog: `Removed ${id}. Was <code>${_escape(JSON.stringify(state.projectRoster?.items?.[id]))}</code>`,
      };

      return firestore
        .collection('projectFinance')
        .doc(state.opportunityID)
        .collection('laborRates')
        .doc('projectRoster')
        .set(
          {
            items: {
              [id]: firebase.firestore.FieldValue.delete(),
            },
            log: firebase.firestore.FieldValue.arrayUnion(logElement),
            opportunityID: state.opportunityID,
          },
          { merge: true },
        );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
