<template>
  <v-app-bar app elevation="1" color="terciary" dark>
    <v-dialog v-model="routeSOPDialog">
      <v-card>
        <v-container>
          <RouteSOPs v-for="match in matched" :key="match.path" :match="match"> </RouteSOPs>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sopDialog"
      @input="
        sopDialog = false;
        sopID = null;
      "
    >
      <v-card>
        <v-container>
          <SOPForm v-if="sopDialog" :sopID="sopID" :embedded="true"></SOPForm>
        </v-container>
      </v-card>
    </v-dialog>

    <v-app-bar-nav-icon @click.stop="navButtonToggle()"></v-app-bar-nav-icon>

    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

    <v-toolbar light dense height="38px" rounded elevation="1">
      <v-menu offset-y v-model="menu" :close-on-click="true" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-container>
            <v-text-field
              @click="menu = true"
              v-model="search"
              hide-details
              prepend-icon="mdi-magnify"
              placeholder="Search opportunities, routes, ..."
              single-line
              dense
              flat
              solo
            >
            </v-text-field>
          </v-container>
        </template>

        <v-list v-if="searchResult.routeItems.length > 0 || searchResult.opptyItems.length > 0">
          <v-list-item
            v-for="route of searchResult.routeItems"
            :key="route.path"
            link
            @mousedown="goToRoute(route.path)"
          >
            <v-list-item-content>
              <v-list-item-title> #{{ route.name }} </v-list-item-title>
              <v-list-item-subtitle>
                {{ route.path }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-for="oppty of searchResult.opptyItems" :key="oppty.opportunityRecordID" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Opportunity: {{ oppty.opportunityName }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="route in oppty.routes"
              :key="route.name + oppty.opportunityRecordID"
              :to="{
                name: route.name,
                params: {
                  [route.param]: oppty.opportunityRecordID,
                },
              }"
              @click="menu = false"
              link
            >
              <v-list-item-title>{{ route.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

    <v-col cols="1">
      <v-menu v-if="sops.length" open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="yellow">mdi-information-outline</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link v-for="sop of sops" :key="sop.id" @click="handleSOP(sop.sop)">
            <v-list-item-content>
              <v-list-item-title>
                {{ sop.sopName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col cols="1">
      <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="45px" v-bind="attrs" v-on="on">
            <v-img
              :src="
                user.photoURL ||
                'https://firebasestorage.googleapis.com/v0/b/lightedison-devserver.appspot.com/o/0.png?alt=media&token=ae12ac21-5b38-4293-847e-95b67889a30f'
              "
            ></v-img>
          </v-avatar>
        </template>

        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.displayName }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item link @click.stop="$emit('profile', user)">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item link @click.stop="$emit('password')">
            <v-list-item-icon>
              <v-icon>mdi-form-textbox-password</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>
          <v-list-item :dark="$vuetify.theme.dark" link @click.stop="$vuetify.theme.dark = !$vuetify.theme.dark">
            <v-list-item-icon
              ><v-icon>mdi-{{ $vuetify.theme.dark ? 'weather-sunny' : 'weather-night' }}</v-icon></v-list-item-icon
            >
            <v-list-item-title>{{ $vuetify.theme.dark ? 'Light' : 'Dark' }} Mode</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="handleRouteSOP" v-if="canAddSOPs">
            <v-list-item-icon><v-icon>mdi-file-document-outline</v-icon></v-list-item-icon>
            <v-list-item-title>Add SOP to Route</v-list-item-title>
          </v-list-item>
          <v-list-item link @click.stop="linkWithGoogle" v-if="showLinkWithGoogle">
            <v-list-item-title>Link with Google</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item link @click.stop="$emit('logout')">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-app-bar>
</template>

<script>
import RouteSOPs from '@/components/route-sops/RouteSOPs.vue';
import RouteSOPController from '@/core/interfaces/route-sops/RouteSOPController';
import SOPForm from '@/pages/customer_relationships_management/sops/sop_form/SOPForm.vue';
import { checkPermissions } from '@/services/access-control/AccessControlService';
import firebase from 'firebase';
import EventBus from '@/utils/event-bus';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    RouteSOPs,
    SOPForm,
  },
  data() {
    return {
      user: {
        photoURL: null,
        email: null,
        displayName: null,
        phoneNumber: null,
      },
      sops: [],
      canAddSOPs: false,
      search: null,
      model: null,
      isLoading: false,
      menu: false,
      showLinkWithGoogle: false,
      routeSOPDialog: false,
      sopDialog: false,
      sopID: null,
      matched: [],
    };
  },
  mounted() {
    const firebaseAuth = firebase.auth();
    const currentUser = firebaseAuth.currentUser;
    if (currentUser.email && currentUser.email.includes('@lightedison.com')) {
      firebaseAuth.fetchSignInMethodsForEmail(currentUser.email).then((providers) => {
        this.showLinkWithGoogle = !providers.includes('google.com');
      });
    }
    const { photoURL, email, displayName, phoneNumber } = currentUser;

    this.user.photoURL = photoURL;
    this.user.email = email;
    this.user.displayName = displayName;
    this.user.phoneNumber = phoneNumber;

    //
    checkPermissions({
      routeSOPs: ['create', 'read', 'update', 'delete'],
    }).then((canAddSOPs) => {
      this.canAddSOPs = !!canAddSOPs;
    });

    EventBus.$on('OpenSOP', (event) => {
      if (event && event.id) {
        this.handleSOP(event.id);
      }
    });
  },
  computed: {
    routerItems() {
      return this.$router.options.routes.filter((route) => !route.path.includes(':'));
    },
    opptyRouterItems() {
      return this.$router.options.routes.filter((route) => route.meta && route.meta.opptySearchable);
    },
    ...mapGetters('utils', ['getDrawer']),
    ...mapGetters('opportunities', ['opportunities']),
    searchResult() {
      if (!this.search || !this.search.length) {
        return { routeItems: [], opptyItems: [] };
      }

      const items = this.routerItems
        .filter((item) => {
          const itemName = item.name.toLowerCase();
          const searchValue = this.search.toLowerCase();

          return itemName.includes(searchValue) || item.path.includes(searchValue);
        })
        .slice(0, 10);

      const opptyItems =
        this.search.length < 4
          ? []
          : this.opportunities
              .filter(
                (oppty) =>
                  oppty.opportunityRecordID === this.search ||
                  oppty.opportunityName.toLowerCase().includes(this.search.toLowerCase()),
              )
              .map(({ opportunityRecordID, opportunityName }) => ({
                opportunityRecordID,
                opportunityName,
                routes: this.opptyRouterItems.map(({ name, path }) => ({
                  name,
                  param: path.match(/:([^\/]+)/)[1],
                })),
              }))
              .slice(0, 10);

      return { routeItems: items, opptyItems };
    },
  },
  watch: {
    $route(to) {
      if (!to.matched[0] || to.matched.length === 0) {
        return;
      }

      const [match] = to.matched;
      RouteSOPController.getRouteSOPs({
        filters: [{ field: 'route', operator: '==', value: match.path }],
      })
        .then((routeSOPs) => {
          this.sops = routeSOPs;
        })
        .catch((error) => {
          const errorObject = {
            type: 'error',
            message: 'Error in fetching SOPs by route.',
          };

          this.addNotification(errorObject);
          console.log(error);
        });
    },
  },
  methods: {
    ...mapMutations('utils', ['navButtonToggle']),
    ...mapActions('notifications', ['addNotification']),
    goToRoute(path) {
      this.$router.push({ path });
    },
    handleSOP(sopID) {
      this.sopID = sopID;
      this.sopDialog = true;
    },
    handleRouteSOP() {
      this.matched = (this.$route.matched || []).map(({ name, path }) => ({
        name,
        path,
      }));
      this.routeSOPDialog = true;
    },
    linkWithGoogle: function () {
      firebase
        .auth()
        .currentUser.linkWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((result) => {
          // Accounts successfully linked.
          swal('Success', 'Accounts successfully linked.', 'success');
        })
        .catch((error) => {
          swal('Error', error.message, 'error');
        });
    },
  },
};
</script>

<style scoped>
:deep(.theme-changer .v-input--selection-controls__input) {
  margin-right: 0;
}
:deep(.theme-changer .v-input__slot) {
  justify-content: right;
}
</style>
