import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

// @ts-ignore
Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '409cff',
        secondary: '#c9e2ff',
        terciary: '#1c4060',
      },
    },
  },
};

// @ts-ignore
export default new Vuetify(opts);
