<template lang="pug">
  v-radio-group(
    :value='modelValue'
    @change="$emit('change', $event)"
    dense
    :label='label || name'
    v-bind='$attrs'
    :error-messages='errors'
    :error-count="errors?.length || 0")
    v-radio(v-for='item in items' :key='item instanceof Object ? item[itemValue] : item' :label='item instanceof Object ? item[itemText] : item' :value='item instanceof Object ? item[itemValue] : item' :color='item instanceof Object ? item.color : undefined')

    template(v-for='(_, field) in $scopedSlots' v-slot:[field]='slotProps')
      slot(:name='field' v-bind='slotProps')
    slot(v-for='(_, name) in $slots' :name='name' :slot='name')

    template(v-slot:label)
      slot(name='label' :required="required")
        | {{ label || name }}
        label.red--text(v-if='required') *
</template>

<script lang="ts">
export default {
  name: 'BaseRadioGroup',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    itemText: {
      type: String,
      default: 'label',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    required: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    /** @deprecated this doesn't match the rest of the system and needs to be removed */
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
