// IMPORTANT: This will be implemented by the google library
// (and not just the firebase library)
import * as VARIABLES from '@/config/variables.js';
import firebase from 'firebase';
import AuthenticationGateway from '@/core/features/auth/interfaces/AuthenticationGateway';
import User from '@/core/entities/User';

declare let google: any;

export default class AuthenticationGatewayFirebaseImpl implements AuthenticationGateway {
  async getCurrentUser() {
    try {
      const auth = firebase.auth();
      return auth.currentUser ? new User(auth.currentUser) : null;
    } catch {
      throw new Error('Error getting current user');
    }
  }

  async getCustomClaimsFromCurrentUser() {
    try {
      const auth = firebase.auth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No current user');
      }
      const token = await currentUser.getIdTokenResult();
      return token.claims;
    } catch (error) {
      throw new Error('Error getting custom claims');
    }
  }

  async getAccessTokenFromCurrentUser(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: VARIABLES.GOOGLE_CLIENT_ID,
        prompt: null,
        scope: `https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/calendar`,
        callback: ({ access_token }) => resolve(access_token),
        error_callback: (errorResponse) => reject(errorResponse),
      });

      tokenClient.requestAccessToken();
    });
  }
}
