export const OrgChartRoles = [
  {
    id: 'existing-position',
    name: 'Existing Position',
    borderStyle: '2px solid #0C58D3',
  },
  {
    id: 'posted-open-position',
    name: 'Posted Open Position',
    borderStyle: '2px dashed #0C58D3',
  },
  {
    id: 'planned-future-position',
    name: 'Planned Future Position',
    borderStyle: '2px dashed #0F9D58',
  },
  {
    id: 'new-role-position',
    name: 'New Role/Position',
    borderStyle: '2px solid #0F9D58',
  },
  {
    id: 'consultant',
    name: 'Consultant',
    borderStyle: '2px solid #f4b400',
  },
];

export const OrgChartTier = [
  {
    name: 'CEO',
    color: '#5d058d',
  },
  {
    name: 'VP',
    color: '#0c058d',
  },
  {
    name: 'Director',
    color: '#058d1c',
  },
  {
    name: 'Manager',
    color: '#8d6e05',
  },
  {
    name: 'Contributor',
    color: '#8d2305',
  },
];
//"#0c058d","#05668d","#8d6e05","#8d2305","#cfb303"
