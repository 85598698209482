<template lang="pug">
v-autocomplete(
  v-on='clisteners'
  :label='label'
  :items='items'
  :error-messages='errors'
  :error-count="errors?.length || 0"
  :value='modelValue'
  @change="$emit('change', handlerFunction ? handlerFunction($event) : $event)"
  @focus="$emit('focus', focusHandler ? focusHandler($event): $event)"
  :dense='dense'
  v-bind='$attrs'
  :class="{'has-action-outer': !!hasAdd || !!hasView}")

  template(v-for='(_, field) in $scopedSlots' v-slot:[field]='slotProps')
    slot(:name='field' v-bind='slotProps')
  slot(v-for='(_, name) in $slots' :name='name' :slot='name')

  template(v-slot:label)
    slot(name='label' :required="required")
      | {{ label }}
      label.red--text(v-if='required') *

  //- handle item with subtext
  template(v-if="!!itemSubText" v-slot:item='{ parent, item, on, attrs }')
    v-list-item(v-bind='attrs' v-on='on')
      v-list-item-content
        v-list-item-title(v-html='maskedInput2html(parent.getMaskedCharacters(parent.getText(item)))')
        v-list-item-subtitle(v-html='maskedInput2html(parent.getMaskedCharacters(item?.[itemSubText] ?? ""))')

  template(v-if="!!itemSubText" v-slot:selection='{ parent, item, index, select, selected, disabled }')
    div.v-select__selection.v-select__selection--comma(:class="{'v-select__selection--disabled': disabled}")
      v-list-item-title {{parent.getText(item)}}
      v-list-item-subtitle
        small {{item?.[itemSubText] ?? ""}}

  template(v-slot:append-outer v-if='!!hasAdd || !!hasView')
    slot(name="append-outer")
      v-btn(icon @click='$emit("add")' title="Add New Record" v-if="!!hasAdd")
        v-icon(color='primary') mdi-plus
      v-btn(icon :to="viewLink" @click.native.capture.stop.prevent='$emit("view")'
        title="View Record" v-if="!!hasView")
        v-icon(color='primary') mdi-eye-outline
</template>

<script lang="ts">
import { maskedInput2html } from '@/services/utils/Utils';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    handlerFunction: {
      type: Function,
    },
    // added in case of required field
    label: {
      type: String,
      default: '',
    },
    // added because the default in input is false
    dense: {
      type: Boolean,
      default: true,
    },
    // not really useful but makes the html netter since it won't show as attr
    items: {
      type: Array,
      required: true,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    // added for custom handling returned data
    modelValue: {
      type: [String, Object, Number, Array, Boolean],
    },
    // common used option
    hasAdd: {
      type: null,
      default: false,
    },
    hasView: {
      type: null,
      default: false,
    },
    viewLink: {
      type: [String, Object],
      default: undefined,
    },
    itemSubText: {
      type: String,
      default: null,
    },
    focusHandler: {
      type: Function,
    },
  },
  computed: {
    clisteners: function () {
      const { change, input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  methods: {
    maskedInput2html,
  },
};
</script>

<style>
.has-action-outer.v-input--dense .v-input__append-outer {
  margin: 0 !important;
}
</style>
