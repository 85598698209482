<template>
  <v-snackbar
    v-model="snackbar"
    light
    :color="notification.type === 'success' ? 'green accent-1' : ''"
    :dark="notification.type === 'success'"
  >
    <p :class="notification.type === 'error' ? 'red--text mb-0' : 'mb-0'">
      {{ notification.message }}
    </p>

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { mapActions } from 'vuex';
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      snackbar: true,
      timeout: null,
    };
  },
  mounted() {
    this.timeout = setTimeout(() => this.removeNotification(this.notification), 5000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions('notifications', ['removeNotification']),
  },
};
</script>

<style></style>
