import Vue from 'vue';

import { currentUser, firestoreDocument } from '@/services/utils/Utils.js';

import { getRecords, saveRecord, getRecord } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

import { withQuery } from '@/services/utils/Utils.js';

import { getCustomClaimsFromCurrentUser } from '@/services/auth/AuthService';

import _chunk from 'lodash/chunk';
import { InstallerRole, PATHInstallerRole, LightingReviewerRole, JVPartnerRole } from '@/config/variables';

export const namespaced = true;

export const state = {
  opportunities: {},
  subscription: null,
};

export const getters = {
  opportunities: ({ opportunities }) =>
    Object.values(opportunities).map((item) => ({
      ...item,
      name: item.opportunityName,
    })),
  opportunity:
    ({ opportunities }) =>
    (id) =>
      opportunities[id] || undefined,
  opportunities4picklist: ({ opportunities }) =>
    Object.values(opportunities).map(({ opportunityRecordID: id, opportunityName: name }) => ({ id, name })),
};

export const mutations = {
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
  SET_RECORD({ opportunities }, data) {
    Vue.set(opportunities, data.id, data);
  },
  REMOVE_RECORD(state, id) {
    Vue.delete(state.opportunities, id);
  },
  SET_OPPORTUNITIES(state, opportunities) {
    state.opportunities = opportunities;
  },
};

export const actions = {
  async fetchOpportunities({ state, commit }, whereStatements = [['archived', '==', false]]) {
    const { role } = await getCustomClaimsFromCurrentUser();
    if ([InstallerRole, PATHInstallerRole, LightingReviewerRole, JVPartnerRole].includes(role)) {
      let assignedOpportunities = [];
      try {
        const { opptyIDs } = await getRecord({
          collectionName: 'installerOpportunities',
          id: currentUser().uid,
        });
        assignedOpportunities = opptyIDs;
      } catch (error) {
        console.error(error.message);
      }
      if (assignedOpportunities && assignedOpportunities.length) {
        // Reminder that "getRecords" has a workaround for the limit
        // imposed at the "IN" operator (up to 10 items)
        const assignedOpportunityQuery = getRecords({
          collectionName: 'opportunities',
          whereStatements: [...whereStatements, ['opportunityRecordID', 'in', assignedOpportunities]],
        });
        const opportunities = await assignedOpportunityQuery;
        const opportunitiesObject = opportunities.reduce(
          (acc, opportunity) => ({
            ...acc,
            [opportunity.id]: opportunity,
          }),
          {},
        );

        commit('SET_OPPORTUNITIES', opportunitiesObject);
      }
    } else if (!state.subscription) {
      const firestore = firebase.firestore();
      const collection = firestore.collection('opportunities');
      const collectionWithQuery = withQuery(whereStatements)(collection);
      let initial = true;

      const subscription = collectionWithQuery.onSnapshot((querySnapshot) => {
        const tempOpportunities = {};
        querySnapshot.docChanges().forEach((change) => {
          if (initial) {
            Vue.set(tempOpportunities, change.doc.id, {
              id: change.doc.id,
              ...change.doc.data(),
            });
          } else {
            if (change.type === 'removed') {
              commit('REMOVE_RECORD', change.doc.id);
            } else {
              commit('SET_RECORD', { id: change.doc.id, ...change.doc.data() });
            }
          }
        });
        if (initial) {
          initial = false;
          commit('SET_OPPORTUNITIES', tempOpportunities);
        }
      });
      commit('SET_SUBSCRIPTION', subscription);
    }
  },
  saveOpportunity(context, opportunityOptions) {
    return saveRecord(opportunityOptions).then((id) => id);
  },
  archiveOpportunity(context, { opportunityId, archived }) {
    if (!opportunityId) {
      throw Error('Opportunity ID is required');
    }
    if (typeof archived !== 'boolean') {
      throw Error('Archived is not a boolean value');
    }

    const opportunityDocument = firestoreDocument('opportunities')(opportunityId);
    return opportunityDocument.update({ archived });
  },
  fetchOpportunity({ getters }, id) {
    return new Promise((resolve, reject) => {
      const opportunityDocument = firestoreDocument('opportunities')(id);
      opportunityDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, { id, data }) {
    if (!id || !data || typeof data !== 'object') {
      throw Error('invalid input');
    }

    return firestoreDocument('opportunities')(id).update({
      ...data,
      modifiedBy: currentUser().uid,
      modifiedDate: new Date().toISOString(),
    });
  },
};
