<template lang="pug">
  // Default event is being set to @change, since @input causes a lot of
  // performance issues when there's too much input on a given form
  // I (Robert Lopez) am going to attempt having our own input components in a progressive manner
  div(v-if='compact')
    label(v-if='label' for='inputValue') {{ label }}
    input#inputValue.lightedison__text-field(
      v-on='clisteners'
      :value='modelValue'
      @change="!regular && $emit('change', handleEvent($event))"
      @input="regular && $emit('change', handleEvent($event))"
      type='text'
      :placeholder='placeholder'
    )

  v-text-field(
    v-else
    v-on='clisteners'
    :label='label'
    :value='modelValue'
    @change="!regular && $emit('change', $event)"
    @input="regular && $emit('change', $event)"
    dense
    :error-messages='errors'
    :error-count='errors?.length || 0'
    :placeholder='placeholder'
    v-bind='$attrs'
  )

    template(v-for='(_, field) in $scopedSlots' v-slot:[field]='slotProps')
      slot(:name='field' v-bind='slotProps')
    slot(v-for='(_, name) in $slots' :name='name' :slot='name')

    template(v-slot:label)
      slot(name='label')
        | {{ label }}
        label.red--text(v-if='required') *

    template(v-slot:append v-if='hasEdit')
      v-tooltip(top)
        template(v-slot:activator='{ on, attrs }')
          a(v-bind='attrs' v-on='on' @click='$emit("edit")')
            v-icon(small color='primary') mdi-pencil
        span Edit {{ label }}
</template>

<script lang="ts">
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    compact: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    regular: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hasEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEvent(event) {
      return event.target.value;
    },
  },
  computed: {
    clisteners: function () {
      const { change, input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>

<style scoped>
.lightedison__text-field {
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #4a4a4a;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s ease-in-out;
}

.lightedison__text-field:focus {
  outline: none;
  border: 1px solid #409cff;
}
</style>
