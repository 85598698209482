// This module serves as a store to autopopulate fields on time entry form
// Depending on the clicked child Table

const state = {
  populationType: null,
  opportunityToPrepopulate: {
    name: null,
    id: null,
  },
  vacationToPrepopulate: {
    vacationType: null,
  },
  corporateToPrepopulate: {
    division: {
      text: null,
      id: null,
    },
    department: {
      text: null,
      id: null,
    },
  },
};

const getters = {
  opportunityToPrepopulate(state) {
    return { ...state.opportunityToPrepopulate };
  },
  corporateToPrepopulate(state) {
    return { ...state.corporateToPrepopulate };
  },
  vacationToPrepopulate(state) {
    return { ...state.vacationToPrepopulate };
  },
  populationType(state) {
    return state.populationType;
  },
};

const mutations = {
  setVacationToPrepopulate(state, payload) {
    const { vacationType, type } = payload;

    state.vacationToPrepopulate.vacationType = vacationType;
    state.populationType = type;
  },
  setOpportunityToPrepopulate(state, payload) {
    const { name, id, type } = payload;

    if (!(name && id && type)) {
      return;
    }

    state.opportunityToPrepopulate.name = name;
    state.opportunityToPrepopulate.id = id;
    state.populationType = type;
  },
  setCorporateToPrepopulate(state, payload) {
    const { division, department, type } = payload;

    const validSubmission = (object) => {
      if (!(object && typeof object === 'object' && !Array.isArray(object))) {
        return false;
      }

      for (const key in object) {
        if (!(object[key] && object[key].length)) {
          return false;
        }
      }
      return true;
    };

    if (!(validSubmission(division) && validSubmission(department))) {
      return;
    }

    // Division Population
    state.corporateToPrepopulate.division.text = division.text;
    state.corporateToPrepopulate.division.id = division.id;

    // Department Population
    state.corporateToPrepopulate.department.text = department.text;
    state.corporateToPrepopulate.department.id = department.id;

    // Type population
    state.populationType = type;
  },
  resetState(state) {
    // Opportunity reset
    state.opportunityToPrepopulate.name = null;
    state.opportunityToPrepopulate.id = null;

    // Corporate reset
    state.corporateToPrepopulate.division.text = null;
    state.corporateToPrepopulate.division.id = null;

    state.corporateToPrepopulate.department.text = null;
    state.corporateToPrepopulate.department.id = null;

    // Population type reset
    state.populationType = null;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
};
