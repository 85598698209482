import { firestoreDocument } from '@/services/utils/Utils.js';

//
import firebase from 'firebase';
import { saveRecord, getRecord } from '@/services/firebase/FirestoreService.js';

//
import { pictureUpload, deleteImages } from '@/services/firebase/StorageService.js';

export const namespaced = true;

export const state = {
  checklists: [],
  subscription: null,
  statements: [],
};

export const getters = {
  checklists: (state) => state.checklists,
};

export const mutations = {
  SET_CHECKLISTS(state, checklists) {
    state.checklists = checklists;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
  SET_STATEMENTS(state, statements) {
    state.statements = statements;
  },
};

export const actions = {
  async fetchChecklists({ state, commit }, queryConfig) {
    //
    const isObject = (value) => value && !Array.isArray(value) && typeof value == 'object';
    const functions = firebase.functions();
    const getRecords = functions.httpsCallable('getRecords');
    return getRecords({
      ...(isObject(queryConfig) ? queryConfig : {}),
      collectionName: 'checklists',
    }).then(({ data }) => {
      commit('SET_CHECKLISTS', data);
    });
  },
  async saveChecklist(context, checklistsOptions) {
    // An initial array for saving picture urls of the pictures
    // being updloaded in this submission, just to delete all of them
    // in case an error occurs during the submission.
    const uploadedPicturesForErrorHandling = [];

    //
    const { data } = checklistsOptions;
    try {
      const inspectionItems = await Promise.all(
        (data.inspectionItems || []).map(async ({ localPictures = [], ...data }) => {
          // Uploading pictures
          const uploadedPictures = await pictureUpload(localPictures, 'picture_uploads/checklists');

          // Preparing inspection item data
          const inspectionItem = {
            ...data,
            pictures: [...(data.pictures || []), ...uploadedPictures],
          };

          // Side Effect of pushing the uploaded pictures to the "uploadedPicturesForErrorHandling"
          // In case of a submission error.
          uploadedPicturesForErrorHandling.push(...uploadedPictures);
          return inspectionItem;
        }),
      );

      return saveRecord({
        ...checklistsOptions,
        data: {
          ...data,
          inspectionItems,
        },
      }).then((id) => id);
    } catch (error) {
      deleteImages(uploadedPicturesForErrorHandling);
      console.log('The error', error);
    }
  },
  fetchChecklist(context, id) {
    return new Promise((resolve, reject) => {
      const checklistDocument = firestoreDocument('checklists')(id);
      checklistDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteChecklistImage(context, { url, checklistId }) {
    if (!url || !checklistId) {
      throw 'URL, Checklist record ID and Inspection Item index are required.';
    }

    //
    const inspectionItemsMapper = ({ inspectionItems = [] }) =>
      inspectionItems.map((inspectionItem, index) => {
        const pictures = inspectionItem.pictures || [];
        return {
          ...inspectionItem,
          pictures: pictures.filter(({ url: pictureUrl }) => pictureUrl !== url),
        };
      });

    try {
      //
      const record = await getRecord({
        collectionName: 'checklists',
        id: checklistId,
      });

      //
      const inspectionItems = inspectionItemsMapper(record);

      //
      await deleteImages([{ url }]);
      return await saveRecord({
        id: checklistId,
        collectionName: 'checklists',
        data: { inspectionItems },
      });
    } catch (error) {
      console.log(error);
    }
  },
  async deleteChecklist(context, id) {
    if (!id) {
      throw new Error('Checklist ID is required.');
    }
    const firestore = firebase.firestore();
    const checklistDocument = firestore.doc(`checklists/${id}`);

    return checklistDocument.delete();
  },
};
