import RouteSOPRepository from '@/core/interfaces/repositories/RouteSOPRepository';
import RouteSOP from '@/core/entities/RouteSOP';
import { SOPQueryInterface } from '../sops/interfaces/sopQuery';

export default class GetRouteSOPsUseCase {
  private routeSOPRepository: RouteSOPRepository;

  constructor(routeSOPRepository: RouteSOPRepository) {
    this.routeSOPRepository = routeSOPRepository;
  }

  async execute(query: SOPQueryInterface): Promise<RouteSOP[]> {
    const newRouteSOP = await this.routeSOPRepository.getRouteSOPs(query);
    return newRouteSOP;
  }
}
