<template>
  <div>
    <h4>{{ match.name }} ({{ match.path }})</h4>
    <v-divider></v-divider>
    <v-combobox
      outlined
      :value="chips"
      @change="handleRouteSOPs"
      :items="sops"
      clearable
      label="Add Routes"
      multiple
      prepend-icon="mdi-filter-variant"
      return-object
      item-text="name"
      item-value="id"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="removeChip(item)">
          <strong>{{ item.name }}</strong
          >&nbsp;
          <span>{{ Number.isInteger(toNumber(item.versionCount)) ? `(v${item.versionCount.toFixed(2)})` : `` }}</span>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script lang="ts">
import RouteSOPController from '@/core/interfaces/route-sops/RouteSOPController';
import { toNumber } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'RouteSops',
  props: {
    match: {
      type: Object,
      required: true,
      validator: ({ path, name }) => {
        if (!path || !name) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  data() {
    return {
      routeSOPs: [],
      chips: [],
    };
  },
  methods: {
    ...mapActions('sops', ['fetchSOPs']),
    ...mapActions('notifications', ['addNotification']),
    toNumber,

    //
    getRouteSOPs(route) {
      return RouteSOPController.getRouteSOPs({
        filters: [{ field: 'route', operator: '==', value: route }],
      })
        .then((routeSOPs) => {
          this.routeSOPs = routeSOPs;
          console.log('RouteSOPs: ', routeSOPs);
          this.chips = routeSOPs.map((routeSOP) => {
            return {
              routeSOPId: routeSOP.id,
              id: routeSOP.sop,
              name: routeSOP.sopName,
              versionCount: routeSOP.sopVersion,
            };
          });
          console.log('Chips: ', this.chips);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createRouteSOP(routeSOP) {
      RouteSOPController.createRouteSOP(routeSOP)
        .then(() => {
          this.getRouteSOPs(this.match.path);
        })
        .then(() => {
          const successObject = {
            type: 'success',
            message: 'SOP added to route successfully!',
          };
          this.addNotification(successObject);
        })
        .catch((error) => {
          const errorObject = {
            type: 'error',
            message: 'Error in adding SOP to route.',
          };

          this.addNotification(errorObject);
          console.log(error);
        });
    },
    deleteRouteSOP(id) {
      RouteSOPController.deleteRouteSOP(id)
        .then(() => {
          this.getRouteSOPs(this.match.path);
        })
        .then(() => {
          const successObject = {
            type: 'success',
            message: 'SOP removed from route successfully!',
          };
          this.addNotification(successObject);
        })
        .catch((error) => {
          const errorObject = {
            type: 'error',
            message: 'Error in deleting SOP to route.',
          };

          this.addNotification(errorObject);
          console.log(error);
        });
    },
    removeChip(item) {
      const newChips = JSON.parse(JSON.stringify(this.chips));
      newChips.splice(this.chips.indexOf(item), 1);
      this.handleRouteSOPs(newChips);
    },
    handleRouteSOPs(value) {
      const newValue = value;
      const oldValue = this.chips;

      if (oldValue.length != newValue.length) {
        if (oldValue.length < newValue.length) {
          // Add new SOP from route
          const [addedItem] = newValue.filter(
            (newValueItem) => !oldValue.some((oldValueItem) => oldValueItem.id === newValueItem.id),
          );
          const routeSOP = {
            route: this.match.path,
            sop: addedItem.id,
            sopName: addedItem.name,
            sopVersion: addedItem.versionCount,
          };

          this.createRouteSOP(routeSOP);
        } else {
          // Delete existing SOP from route
          // remove
          const [removedItem] = oldValue.filter(
            (oldValueItem) => !newValue.some((newValueItem) => newValueItem.id === oldValueItem.id),
          );
          this.deleteRouteSOP(removedItem.routeSOPId);
        }
      }
    },
  },
  computed: {
    ...mapGetters('sops', ['sops']),
  },
  async created() {
    this.fetchSOPs();
    this.getRouteSOPs(this.match.path);
  },
};
</script>

<style></style>
