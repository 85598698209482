import CreateRouteSOPUseCase from '@/core/use-cases/route-sops/createRouteSOP';
import DeleteRouteSOPUseCase from '@/core/use-cases/route-sops/deleteRouteSOP';
import GetRouteSOPsUseCase from '@/core/use-cases/route-sops/getRouteSOPs';
import RouteSOPsRepositoryFirestoreImpl from '@/infrastructure/data-providers/route-sops/RouteSOPsRepositoryFirestoreImpl';
import RouteSOP from '@/core/entities/RouteSOP';
import { SOPQueryInterface } from '@/core/use-cases/sops/interfaces/sopQuery';

const repository = new RouteSOPsRepositoryFirestoreImpl();
const createRouteSOPUseCase = new CreateRouteSOPUseCase(repository);
const deleteRouteSOPUseCase = new DeleteRouteSOPUseCase(repository);
const getRouteSOPsUseCase = new GetRouteSOPsUseCase(repository);

export default {
  createRouteSOP: (routeSOP: RouteSOP) => {
    return createRouteSOPUseCase
      .execute(routeSOP)
      .then((routeSOP) => routeSOP)
      .catch((error) => error);
  },
  getRouteSOPs(query: SOPQueryInterface) {
    return getRouteSOPsUseCase
      .execute(query)
      .then((routeSOPs) => routeSOPs)
      .catch((error) => error);
  },
  deleteRouteSOP(id: string) {
    return deleteRouteSOPUseCase
      .execute(id)
      .then((routeSOP) => routeSOP)
      .catch((error) => error);
  },
};
