import { getRecord, saveRecord } from '@/services/firebase/FirestoreService.js';
import firebase from 'firebase';

export const namespaced = true;

export const state = {
  roles: [],
  collections: [],
  subscription: null,
};

export const getters = {
  roles: ({ roles }) => roles,
  collections: ({ collections }) => collections,
  role: (state) => (id) => state.roles.find((role) => role.id === id),
};

export const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_COLLECTIONS(state, collections) {
    state.collections = collections;
  },
};

export const actions = {
  async fetchRoles({ state, commit }, queryConfig) {
    //
    const isObject = (value) => value && !Array.isArray(value) && typeof value == 'object';
    const functions = firebase.functions();
    const getRecords = functions.httpsCallable('getRecords');
    return getRecords({
      ...(isObject(queryConfig) ? queryConfig : {}),
      collectionName: 'roles',
    }).then(({ data: roles }) => {
      commit('SET_ROLES', roles);
    });
  },
  async fetchCollections({ state, commit }) {
    const functions = firebase.functions();
    const getCollectionNames = functions.httpsCallable('getCollectionNames');
    return getCollectionNames().then(({ data: collections }) => {
      commit('SET_COLLECTIONS', collections || []);
    });
  },
  saveRole(context, roleOptions) {
    return saveRecord(roleOptions).then((id) => id);
  },
  fetchRole(context, id) {
    return getRecord({
      collectionName: 'roles',
      id,
    }).then((record) => record);
  },
};
