import { firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord, getRecords } from '@/services/firebase/FirestoreService.js';

export const namespaced = true;

export const state = {
  designs: [],
  subscription: null,
};

export const getters = {
  designs: ({ designs }) => designs,
  design: (state) => (id) => state.designs.find((design) => design.id === id),
};

export const mutations = {
  SET_DESIGNS(state, designs) {
    state.designs = designs;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchDesigns({ state, commit }, config = {}) {
    return getRecords({
      ...config,
      collectionName: 'designs',
    }).then((records) => {
      commit('SET_DESIGNS', records);
    });
  },
  saveDesign(context, designOptions) {
    return saveRecord(designOptions).then((id) => id);
  },
  fetchDesign(context, id) {
    const designDocument = firestoreDocument('designs')(id);
    return designDocument
      .get()
      .then((querySnapshot) => ({
        ...querySnapshot.data(),
        id: querySnapshot.id,
      }))
      .catch((error) => {
        return error;
      });
  },
};
