import { querySnapshotToArray, firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

export const namespaced = true;

export const state = {
  accounts: [],
  subscription: null,
};

export const getters = {
  accounts: ({ accounts }) => accounts,
  account: (state) => (id) => state.accounts.find((account) => account.id === id),
  accounts4picklist: ({ accounts }) => accounts.map(({ id, name }) => ({ id, name })),
};

export const mutations = {
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchAccounts({ state, commit }) {
    if (state.subscription) {
      return;
    }

    const collection = firebase.firestore().collection('accounts');
    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_ACCOUNTS', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  saveAccount(context, accountOptions) {
    return saveRecord(accountOptions).then((id) => id);
  },
  fetchAccount({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (getters.account(id)) {
        resolve(getters.account(id));
        return;
      }

      const accountDocument = firestoreDocument('accounts')(id);
      accountDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
