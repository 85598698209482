export const Globals = {
  fireBaseUrl: 'https://us-central1-light-edison.cloudfunctions.net/', // 'https://us-central1-lightedison-devserver.cloudfunctions.net/'  'http://localhost:5001/light-edison/us-central1/'
  //fireBaseUrl:  'http://localhost:5001/light-edison/us-central1/' //for local emulation
};

export const GOOGLE_CLIENT_ID = '476956669460-e6m9g61dahrhmatjl58rn9v0lfb7o7an.apps.googleusercontent.com';

// Role IDs
export const AdminRole = '2uRlYOYN7yny6tG9a7wa';
export const SuperAdminRole = 'iGcPQKwKJTHNf5zok6I4';
export const LightingReviewerRole = 'AEgmuSwYEVat7utOfeQC';
export const InstallerRole = 'J5W0WbKNuGzIjZ2Ic23n';
export const PATHInstallerRole = 'Kw5WUEdts2OnHFAlTdno';
export const JVPartnerRole = 'JVPartner';

// User IDs
export const HeatherLloydUserID = 'B0GUlqD2ngfKEw4fvjWnqV58hni2';
export const HeatherSolleyUserID = 'dlob3RqFKJX6jWeJpclJGbzTQ3z1';
