import { querySnapshotToArray, querySnapshotToData, firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';
import { deleteImages } from '@/services/firebase/StorageService.js';

import firebase from 'firebase';

// Storage handler
import { storageHandler } from '@/pages/shared/storageHandler.js';

export const namespaced = true;

export const state = {
  sops: [],
  subscription: null,

  //
  versions: [],
  versionsSubscription: null,
};

export const getters = {
  sops: ({ sops }) => sops,
  sop: (state) => (id) => state.sops.find((sop) => sop.id === id),

  //
  versions: ({ versions }) => {
    const nonVersionedRecords = versions.filter(({ status }) => status == 'Requesting Approval');
    const versionedRecords = versions.filter(({ status }) => status !== 'Requesting Approval');

    //
    const sortedVersionedRecords = [...versionedRecords].sort((a, b) => b.recordNumber - a.recordNumber);
    return [...nonVersionedRecords, ...sortedVersionedRecords];
  },
};

export const mutations = {
  SET_SOPS(state, sops) {
    state.sops = sops;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },

  //
  SET_VERSIONS(state, versions) {
    state.versions = versions;
  },
  SET_VERSIONS_SUBSCRIPTION(state, versionsSubscription) {
    state.versionsSubscription = versionsSubscription;
  },
};

export const actions = {
  async fetchSOPs({ state, commit }, queryConfig) {
    //
    const isObject = (value) => value && !Array.isArray(value) && typeof value == 'object';
    const functions = firebase.functions();
    const getRecords = functions.httpsCallable('getRecords');
    return getRecords({
      ...(isObject(queryConfig) ? queryConfig : {}),
      collectionName: 'sops',
    }).then(async ({ data }) => {
      const sops = await Promise.all(
        data.map(async (sop) => {
          const versionCollection = firestoreDocument('sops')(sop.id).collection('versions');
          return {
            ...sop,
            versions: querySnapshotToArray(await versionCollection.get()),
          };
        }),
      );
      commit('SET_SOPS', sops);
    });
  },
  saveSOP(context, sopOptions) {
    return saveRecord(sopOptions).then((id) => id);
  },
  deleteSOP({ dispatch }, procedureId) {
    // Getting document and versions subcollection from the SOP
    const sopDocument = firestoreDocument('sops')(procedureId);
    const versionsCollection = sopDocument.collection('versions');

    //
    return versionsCollection
      .get()
      .then((querySnapshot) => querySnapshotToArray(querySnapshot))
      .then((versions) => {
        // Preparing the promises to delete the version records
        const promises = versions.map(({ id, pictures = [] }) =>
          dispatch('deleteVersion', {
            procedureId,
            versionId: id,
            pictures,
          }),
        );

        return Promise.all(promises);
      })
      .then(() => sopDocument.delete());
  },
  async saveVersion(context, saveOptions) {
    const { id, procedureId, data, pictures, parentFolder } = saveOptions;

    try {
      if (!procedureId || !Object.keys(data).length) {
        return;
      }

      const firestore = firebase.firestore();
      const storage = firebase.storage();

      //
      const sopDocument = firestore.collection('sops').doc(procedureId);
      const versionsCollection = sopDocument.collection('versions');
      const versionDocument = id ? versionsCollection.doc(id) : versionsCollection.doc();

      //
      const pictureRequest = await storageHandler(storage, pictures, parentFolder);
      const versionData = {
        ...data,
        pictures: [...data.pictures, ...pictureRequest],
      };

      const promise = id ? versionDocument.update(versionData) : versionDocument.set(versionData);

      // Running a transaction in order to read the metadata document
      // And making a new document with the appropiate recordNumber property
      await promise;

      return versionDocument.id;
    } catch (error) {
      console.log(error);
      throw new Error('Error when trying to submit a version record.');
    }
  },
  fetchVersions({ commit }, procedureId) {
    if (!procedureId) {
      throw new Error('Please insert a valid ID.');
    }

    const sopDocument = firestoreDocument('sops')(procedureId);
    const subscription = sopDocument.collection('versions').onSnapshot((querySnapshot) => {
      commit('SET_VERSIONS', querySnapshotToArray(querySnapshot));
    });

    commit('SET_VERSIONS_SUBSCRIPTION', subscription);
  },
  fetchVersion(context, { procedureId, versionId }) {
    if (!procedureId) {
      throw new Error('Please insert a valid ID.');
    }

    const sopDocument = firestoreDocument('sops')(procedureId);
    return sopDocument
      .collection('versions')
      .doc(versionId)
      .get()
      .then((querySnapshot) => querySnapshotToData(querySnapshot));
  },
  deleteVersion(context, { procedureId, versionId, pictures = [] }) {
    if (!versionId || !procedureId) {
      throw new Error('Version ID and Procedure ID are required.');
    }

    const procedureDocument = firestoreDocument('sops')(procedureId);
    const versionDocument = procedureDocument.collection('versions').doc(versionId);

    return deleteImages(pictures).then(() => versionDocument.delete());
  },
  fetchSOP(context, id) {
    const sopDocument = firestoreDocument('sops')(id);
    return sopDocument.get().then((querySnapshot) => ({
      ...querySnapshot.data(),
      id: querySnapshot.id,
    }));
  },
};
