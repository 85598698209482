import firebase from 'firebase';

const state = {};

const getters = {
  createReport:
    () =>
    (metadataDocument, newDocument) =>
    (data = {}) => {
      const firestore = firebase.firestore();
      // Running a transaction in order to read the metadata document
      // And making a new document with the appropiate recordNumber property
      return firestore.runTransaction(async function (transaction) {
        return transaction.get(metadataDocument).then((metadataDoc) => {
          const newCount = metadataDoc.data().count + 1;
          transaction.set(newDocument, {
            ...data,
            recordNumber: newCount,
          });
          transaction.set(metadataDocument, { count: newCount });
        });
      });
    },
};

export default {
  namespaced: true,
  state,
  getters,
};
