import { GenericDate, GenericDateTime } from '@/filters/date';
import momentPlugin from '@/plugins/moment';
import vuetify from '@/plugins/vuetify';
import { padNumber } from '@/services/utils/Utils';
import moment from 'moment-timezone';
import Vue from 'vue';
import autoProgress from 'vue-auto-progress';
import { DynamicReactiveRefs } from 'vue-reactive-refs';
import VueResource from 'vue-resource';
import Vuelidate from 'vuelidate';
import { createVuetifyProTipTap, VuetifyTiptap } from 'vuetify-pro-tiptap';
import { GOOGLE_CLIENT_ID } from './config/variables.js';
import router from './router/index.js';
import { initFirebase } from './services/utils/firebase';
import store from './store/index';
import { checkForNewVersions } from './utils/version-checker.js';

import BaseAutocomplete from '@/components/base/BaseAutocomplete.vue';
import BaseCustomInput from '@/components/base/BaseCustomInput.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseFileManager from '@/components/base/BaseFileManager.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseRadioGroup from '@/components/base/BaseRadioGroup.vue';
import BaseTextArea from '@/components/base/BaseTextArea.vue';
import BaseTextList from '@/components/base/BaseTextList.vue';
import BaseRichTextEditor from '@/components/base/RichTextEditor/BaseRichTextEditor.vue';
import ViewBanner from '@/components/view_banner/ViewBanner.vue';
import App from './App.vue';
import FileContainer from './components/base/FileContainer.vue';
import ImageContainer from './components/image/ImageContainer.vue';

const ALLOWED_HOSTS = ['localhost:8080', 'localhost:8081', 'webapp.lightedison.com', 'grid.lightedison.com'];

const DEFAULT_REDIRECT_URL = 'https://grid.lightedison.com/#/';

function isValidHost(host: string): boolean {
  const isAllowedHost = ALLOWED_HOSTS.includes(host);
  const isIPAddress = /^([0-9]+\.){3}[0-9]+(:[0-9]+)?$/.test(host);
  const isBetaEnvironment = host.includes('beta');

  return isAllowedHost || isIPAddress || isBetaEnvironment;
}

if (!isValidHost(window.location.host)) {
  window.location.href = DEFAULT_REDIRECT_URL;
}

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(Vuelidate);
Vue.use(createVuetifyProTipTap({ vuetify, components: { VuetifyTiptap } }));
Vue.use(DynamicReactiveRefs);
Vue.use(momentPlugin);

Vue.component('le-image', ImageContainer);
Vue.component('le-file', FileContainer);
Vue.component('BaseRadioGroup', BaseRadioGroup);
Vue.component('BaseAutocomplete', BaseAutocomplete);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseTextArea', BaseTextArea);
Vue.component('BaseRichTextEditor', BaseRichTextEditor);
Vue.component('BaseDatePicker', BaseDatePicker);
Vue.component('BaseFileManager', BaseFileManager);
Vue.component('ViewBanner', ViewBanner);
Vue.component('BaseTextList', BaseTextList);
Vue.component('BaseCustomInput', BaseCustomInput);

Vue.filter('genericDate', GenericDate);
Vue.filter('genericDateTime', GenericDateTime);
Vue.filter('currency', (value) => value?.toLocaleString(undefined, { style: 'currency', currency: 'USD' }));
Vue.filter('percentage', (value) => value?.toLocaleString(undefined, { style: 'percent' }));
Vue.filter('recordNumber', (value, prefix, minWidth = 4) => `${prefix}-${padNumber(value, minWidth)}`);

Vue.prototype.$moment = moment;
window.Vue = Vue;

function initGoogleAPI() {
  gapi.client.init({
    clientId: GOOGLE_CLIENT_ID,
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
      'https://sheets.googleapis.com/$discovery/rest?version=v4',
      'https://gmail.googleapis.com/$discovery/rest?version=v1',
      'https://docs.googleapis.com/$discovery/rest?version=v1',
    ],
    scope: `https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/calendar`,
    // see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
  });
}

gapi.load('client', initGoogleAPI);

initFirebase();

let app: Vue | undefined;

window.firebase.auth.onAuthStateChanged((_user) => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      components: { App, autoProgress },
      template: '<div><App/><auto-progress/></div>',
    });
  }
});

if (process.env.NODE_ENV === 'production') {
  console.trace('Production Mode');
  checkForNewVersions();
}
