import { querySnapshotToArray, firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

export const namespaced = true;

export const state = {
  preContractingRecords: [],
  subscription: null,
};

export const getters = {
  preContractingRecords: ({ preContractingRecords }) => preContractingRecords,
  preContractingRecord: (state) => (id) =>
    state.preContractingRecords.find((preContractingRecord) => preContractingRecord.id === id),
};

export const mutations = {
  SET_PRECONTRACTING_RECORDS(state, preContractingRecords) {
    state.preContractingRecords = preContractingRecords;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  async fetchPreContractingRecords({ state, commit }, queryConfig) {
    //
    const isObject = (value) => value && !Array.isArray(value) && typeof value == 'object';
    const functions = firebase.functions();
    const getRecords = functions.httpsCallable('getRecords');
    return getRecords({
      ...(isObject(queryConfig) ? queryConfig : {}),
      collectionName: 'preContracting',
    }).then(({ data }) => {
      commit('SET_PRECONTRACTING_RECORDS', data);
    });
  },
  savePreContractingRecord(context, preContractingOptions) {
    return saveRecord(preContractingOptions).then((id) => id);
  },
  fetchPreContractingRecord(context, id) {
    return new Promise((resolve, reject) => {
      const preContractingDocument = firestoreDocument('preContracting')(id);
      preContractingDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
