import __get from 'lodash/get';
import moment from 'moment';

const state = {
  regularCellFormat: {
    textFormat: {
      fontFamily: 'Open Sans',
      fontSize: 9,
    },
    verticalAlignment: 'TOP',
    horizontalAlignment: 'LEFT',
  },
  regularNumberFormat: {
    textFormat: {
      fontFamily: 'Open Sans',
      fontSize: 9,
    },
    verticalAlignment: 'TOP',
    horizontalAlignment: 'RIGHT',
  },
  firstRowFormat: {
    textFormat: {
      fontFamily: 'Open Sans',
      fontSize: 18,
    },
  },
};

const getters = {
  regularCellFormat: ({ regularCellFormat }) => regularCellFormat,
  regularNumberFormat: ({ regularNumberFormat }) => regularNumberFormat,
  firstRowFormat: ({ firstRowFormat }) => firstRowFormat,
  generateGoogleSheet:
    ({ regularCellFormat, regularNumberFormat, firstRowFormat }) =>
    (sheetName = 'Undefined', informationRows = [], headers = [], data = []) => {
      const infoRows = informationRows.map((row, index) => {
        const sheetRow = {
          values: [],
        };

        for (const column of row) {
          sheetRow.values.push({
            userEnteredValue: {
              stringValue: column + '',
            },
            userEnteredFormat:
              index === 0
                ? { ...firstRowFormat }
                : ['M/D/YY', 'ddd, M/D/YY'].some((format) => moment(column + '', format, true).isValid())
                  ? { ...regularNumberFormat }
                  : { ...regularCellFormat },
          });
        }

        return sheetRow;
      });

      const headersRow = {
        values: headers.map(({ text }) => {
          return {
            userEnteredValue: {
              stringValue: text,
            },
            userEnteredFormat: {
              textFormat: {
                fontFamily: 'Open Sans',
                fontSize: 9,
              },
              horizontalAlignment: 'CENTER',
              verticalAlignment: 'BOTTOM',
              wrapStrategy: 'WRAP',
              borders: {
                bottom: {
                  style: 'SOLID',
                },
              },
            },
          };
        }),
      };

      const rowData = data.map((report) => {
        const values = [];

        headers.forEach(({ value: field, type }) => {
          // IMPORTANT: Here we are assuming that the object will always
          // have a two level nesting
          if (field.includes('.')) {
            const value = __get(report, field);

            const userEnteredValue = {
              stringValue: value ? String(value) : '',
            };

            values.push({
              userEnteredValue,
              userEnteredFormat: type === 'string' ? regularCellFormat : regularNumberFormat,
            });
          } else {
            const value = report[field];

            const userEnteredValue = {
              stringValue: value ? String(value) : '',
            };

            values.push({
              userEnteredValue,
              userEnteredFormat: type === 'string' ? regularCellFormat : regularNumberFormat,
            });
          }
        });

        return { values };
      });

      return {
        properties: {
          title: sheetName,
          gridProperties: {
            rowCount: infoRows.length + rowData.length + 1, // 1 for header row
            columnCount: headers.length
              ? headers.length > 1
                ? headers.length
                : headers.length + 1
              : headers.length + 2,
          },
        },
        data: [
          {
            startRow: 0,
            startColumn: 0,
            rowData: [...infoRows, { ...headersRow }, ...rowData],
          },
        ],
      };
    },
};

export default {
  namespaced: true,
  state,
  getters,
};
