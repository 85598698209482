<template>
  <div class="row border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
      <div class="navbar-header">
        <a v-on:click="smoothOpen()" class="navbar-minimalize minimalize-styl-2 btn btn-primary"
          ><i class="fa fa-bars"></i>
        </a>
        <!--<form role="search" class="navbar-form-custom" action="search_results.html">-->
        <!--<div class="form-group">-->
        <!--<input type="text" placeholder="Search for something..." class="form-control" name="top-search" id="top-search">-->
        <!--</div>-->
        <!--</form>-->
      </div>
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <span class="m-r-sm welcome-message"
            ><b>{{ user.displayName }}</b></span
          >
        </li>
        <li>
          (<b class="text-muted" v-for="(team, index) in teams">
            <span>{{ team.name }}</span>
            <span v-if="index + 1 < teams.length">, </span> </b
          >)
        </li>
        <!--<li class="dropdown">-->
        <!--<a class="dropdown-toggle count-info" data-toggle="dropdown" href="#">-->
        <!--<i class="fa fa-envelope"></i>  <span class="label label-warning">16</span>-->
        <!--</a>-->
        <!--<ul class="dropdown-menu dropdown-messages">-->
        <!--<li>-->
        <!--<div class="dropdown-messages-box">-->
        <!--<a href="profile.html" class="pull-left">-->
        <!--<img alt="image" class="img-circle" src="img/a7.jpg">-->
        <!--</a>-->
        <!--<div class="media-body">-->
        <!--<small class="pull-right">46h ago</small>-->
        <!--<strong>Mike Loreipsum</strong> started following <strong>Monica Smith</strong>. <br>-->
        <!--<small class="text-muted">3 days ago at 7:58 pm - 10.06.2014</small>-->
        <!--</div>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="divider"></li>-->
        <!--<li>-->
        <!--<div class="dropdown-messages-box">-->
        <!--<a href="profile.html" class="pull-left">-->
        <!--<img alt="image" class="img-circle" src="img/a4.jpg">-->
        <!--</a>-->
        <!--<div class="media-body ">-->
        <!--<small class="pull-right text-navy">5h ago</small>-->
        <!--<strong>Chris Johnatan Overtunk</strong> started following <strong>Monica Smith</strong>. <br>-->
        <!--<small class="text-muted">Yesterday 1:21 pm - 11.06.2014</small>-->
        <!--</div>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="divider"></li>-->
        <!--<li>-->
        <!--<div class="dropdown-messages-box">-->
        <!--<a href="profile.html" class="pull-left">-->
        <!--<img alt="image" class="img-circle" src="img/profile.jpg">-->
        <!--</a>-->
        <!--<div class="media-body ">-->
        <!--<small class="pull-right">23h ago</small>-->
        <!--<strong>Monica Smith</strong> love <strong>Kim Smith</strong>. <br>-->
        <!--<small class="text-muted">2 days ago at 2:30 am - 11.06.2014</small>-->
        <!--</div>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="divider"></li>-->
        <!--<li>-->
        <!--<div class="text-center link-block">-->
        <!--<a href="mailbox.html">-->
        <!--<i class="fa fa-envelope"></i> <strong>Read All Messages</strong>-->
        <!--</a>-->
        <!--</div>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->
        <!--<li class="dropdown">-->
        <!--<a class="dropdown-toggle count-info" data-toggle="dropdown" href="#">-->
        <!--<i class="fa fa-bell"></i>  <span class="label label-primary">8</span>-->
        <!--</a>-->
        <!--<ul class="dropdown-menu dropdown-alerts">-->
        <!--<li>-->
        <!--<a href="mailbox.html">-->
        <!--<div>-->
        <!--<i class="fa fa-envelope fa-fw"></i> You have 16 messages-->
        <!--<span class="pull-right text-muted small">4 minutes ago</span>-->
        <!--</div>-->
        <!--</a>-->
        <!--</li>-->
        <!--<li class="divider"></li>-->
        <!--<li>-->
        <!--<a href="profile.html">-->
        <!--<div>-->
        <!--<i class="fa fa-twitter fa-fw"></i> 3 New Followers-->
        <!--<span class="pull-right text-muted small">12 minutes ago</span>-->
        <!--</div>-->
        <!--</a>-->
        <!--</li>-->
        <!--<li class="divider"></li>-->
        <!--<li>-->
        <!--<a href="grid_options.html">-->
        <!--<div>-->
        <!--<i class="fa fa-upload fa-fw"></i> Server Rebooted-->
        <!--<span class="pull-right text-muted small">4 minutes ago</span>-->
        <!--</div>-->
        <!--</a>-->
        <!--</li>-->
        <!--<li class="divider"></li>-->
        <!--<li>-->
        <!--<div class="text-center link-block">-->
        <!--<a href="notifications.html">-->
        <!--<strong>See All Alerts</strong>-->
        <!--<i class="fa fa-angle-right"></i>-->
        <!--</a>-->
        <!--</div>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->

        <li>
          <a v-on:click="logout()"><i class="fa fa-sign-out"></i> Log out</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import fireBase from 'firebase';
export default {
  name: 'AppHeader',
  data() {
    return {
      user: '',
      teams: [],
    };
  },
  created: function () {
    const fireStore = fireBase.firestore();
    const memberPromises = [];
    this.user = fireBase.auth().currentUser;
    // let memberRef = fireStore.collection("members").where("memberEmail", "==", fireBase.auth().currentUser.email);
    // memberRef.get().then(querySnapshot => {
    //   let loggedInMemberTeams = [];
    //   querySnapshot.forEach(function (doc) {
    //     let mData = doc.data();
    //     loggedInMemberTeams.push(mData["teamID"]);
    //   });
    //   let teamPromises = [];
    //   for(let t in loggedInMemberTeams){
    //     let teamPromise = new Promise(function(resolve) {
    //       fireStore.collection("teams").doc(loggedInMemberTeams[t]).get().then(team => {
    //         resolve(team.data());
    //       });
    //     });
    //     teamPromises.push(teamPromise);
    //   }
    //   Promise.all(teamPromises).then( (items) => {
    //     this.teams = [].concat.apply([], items);
    //   });
    // });
  },
  methods: {
    logout: function () {
      fireBase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace('/');
        })
        .catch(function (error) {
          swal('Error!', 'Please try again: ' + error.message, 'error');
        });
    },
    smoothOpen: function () {
      $('body').toggleClass('mini-navbar');
      if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        $('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(function () {
          $('#side-menu').fadeIn(500);
        }, 100);
      } else if ($('body').hasClass('fixed-sidebar')) {
        $('#side-menu').hide();
        setTimeout(function () {
          $('#side-menu').fadeIn(500);
        }, 300);
      } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $('#side-menu').removeAttr('style');
      }
    },
  },
};
</script>

<style scoped></style>
