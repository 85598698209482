import validate from 'validate.js';
import moment from 'moment';

export default class LeaveRequest {
  id?: string;

  // Request Submission
  recordType?: string;
  employee?: string;
  startingOn?: string;
  endingOn?: string;
  leaveRequestReason?: string;
  comments?: string;

  // Request Approval
  status?: 'Untouched' | 'Approved' | 'Rejected';
  verificationComments?: string;
  calendarEventHtmlLink?: string;

  // System Fields
  createdBy?: string;
  createdDate?: string;
  modifiedBy?: string;
  modifiedDate?: string;

  constructor({
    id = null,
    recordType = null,
    employee = null,
    startingOn = null,
    endingOn = null,
    leaveRequestReason = null,
    comments = null,
    status = null,
    verificationComments = null,
    calendarEventHtmlLink = null,
    createdBy = null,
    createdDate = null,
    modifiedBy = null,
    modifiedDate = null,
  }) {
    this.id = id;
    this.recordType = recordType;
    this.employee = employee;
    this.startingOn = startingOn;
    this.endingOn = endingOn;
    this.leaveRequestReason = leaveRequestReason;
    this.comments = comments;
    this.status = status as 'Untouched' | 'Approved' | 'Rejected';
    this.verificationComments = verificationComments;
    this.calendarEventHtmlLink = calendarEventHtmlLink;
    this.createdBy = createdBy;
    this.createdDate = createdDate;
    this.modifiedBy = modifiedBy;
    this.modifiedDate = modifiedDate;
  }

  validate() {
    const validate = this.setValidator();
    const isApprovedOrRejected = ['Approved', 'Rejected'].includes(this.status);

    const presenceTrue = {
      presence: true,
    };
    const constraints = {
      employee: presenceTrue,
      startingOn: {
        ...presenceTrue,
        date: true,
        isProperEndDate: true,
      },
      endingOn: presenceTrue,
      leaveRequestReason: presenceTrue,
      status: presenceTrue,
      verificationComments: {
        presence: isApprovedOrRejected,
      },
    };

    return validate(this, constraints);
  }

  private setValidator() {
    validate.validators.isProperEndDate = (value, options, key, attributes) => {
      const { startingOn, endingOn } = attributes;
      const startDate = new Date(startingOn);
      const endDate = new Date(endingOn);
      if (startDate > endDate) {
        return 'cannot be greater than end date';
      }
    };

    // Before using it we must add the parse and format functions
    // Here is a sample implementation using moment.js
    validate.extend(validate.validators.datetime, {
      // The value is guaranteed not to be null or undefined but otherwise it
      // could be anything.
      parse: function (value, options) {
        return +moment.utc(new Date(value));
      },
      // Input is a unix timestamp
      format: function (value, options) {
        const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
        return moment.utc(new Date(value)).format(format);
      },
    });

    return validate;
  }
}
