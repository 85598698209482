import LeaveRequestsController from '@/core/features/leave-requests/interfaces/LeaveRequestsController.ts';
import { saveRecord } from '@/services/firebase/FirestoreService';
export const namespaced = true;

export const state = {
  vacationRequests: [],
};

export const getters = {
  vacationRequests: ({ vacationRequests }) => vacationRequests,
  vacationRequest: (state) => (id) => state.vacationRequests.find((vacationRequest) => vacationRequest.id === id),
};

export const mutations = {
  SET_VACATION_REQUESTS(state, vacationRequests) {
    state.vacationRequests = vacationRequests;
  },
};

export const actions = {
  async fetchVacationRequests({ state, commit }, { from, to }) {
    const leaveRequestsController = new LeaveRequestsController();
    try {
      const leaveRequests = await leaveRequestsController.getLeaveRequests({
        from,
        to,
      });
      commit('SET_VACATION_REQUESTS', leaveRequests);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  createVacationRequest(context, { data }) {
    const leaveRequestsController = new LeaveRequestsController();
    return leaveRequestsController.createLeaveRequest(data);
  },
  updateVacationRequest(context, { id, data }) {
    const leaveRequestsController = new LeaveRequestsController();
    return leaveRequestsController.updateLeaveRequest({ id, data });
  },
  fetchVacationRequest(context, id) {
    const leaveRequestsController = new LeaveRequestsController();
    return leaveRequestsController.getLeaveRequest({ id });
  },
  verifyVacationRequest(context, { id, status, verificationComments }) {
    const leaveRequestsController = new LeaveRequestsController();
    return leaveRequestsController.verifyLeaveRequest({
      id,
      status,
      verificationComments,
    });
  },
  archiveLeaveRequest(context, { id, archived }) {
    if (!id) {
      throw new Error('No id provided');
    }

    return saveRecord({
      collectionName: 'vacationRequests',
      id,
      data: { archived },
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
