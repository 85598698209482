import RouteSOPRepository from '../../interfaces/repositories/RouteSOPRepository';
import RouteSOP from '../../entities/RouteSOP';

export default class DeleteRouteSOPUseCase {
  private routeSOPRepository: RouteSOPRepository;

  constructor(routeSOPRepository: RouteSOPRepository) {
    this.routeSOPRepository = routeSOPRepository;
  }

  async execute(id: string): Promise<RouteSOP> {
    const deletedRouteSOP = await this.routeSOPRepository.deleteRouteSOP(id);
    return deletedRouteSOP;
  }
}
