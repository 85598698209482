<template>
  <v-card class="light-edison__base-text-list" elevation="0" outlined>
    <slot name="header">
      <v-toolbar elevation="0" dense dark color="terciary">
        <p class="subtitle-2 mb-0">{{ label }}</p>
      </v-toolbar>
    </slot>
    <v-container>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-list-item v-if="!value.length">
            <v-list-item-content>
              <v-list-item-subtitle>Empty list</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-else>
            <v-list-item dense v-for="(item, index) in value" :key="index">
              <v-list-item-content>
                <v-list-item-subtitle>-- {{ item }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="!readonly && !disabled">
                <v-card elevation="0" class="d-flex">
                  <v-btn icon small @click="editItem(index)">
                    <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                  </v-btn>

                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small>
                        <v-icon color="red lighten-1">mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="200px" class="pa-2">
                      <p class="text-center v-label mb-1">Are you sure you want to delete this item?</p>
                      <v-card-actions class="d-flex justify-center">
                        <v-btn dark color="red darken-1" small @click="deleteItem(index)">Delete</v-btn>
                        <v-btn dark color="green darken-1" small>Cancel</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-card>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <slot name="footer" v-bind:addItem="addItem">
      <v-toolbar dark elevation="0" dense color="terciary" :height="readonly ? 20 : 110">
        <v-container v-if="!readonly">
          <v-row>
            <v-col>
              <BaseTextArea
                :label="inputLabel"
                regular
                height="80"
                hide-details="auto"
                outlined
                prepend-icon="mdi-text"
                v-model="textValue"
              >
                <template v-slot:append-outer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="!textValue" icon small v-bind="attrs" v-on="on" @click="addItem(textValue)">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                    <span>Save Skill</span>
                  </v-tooltip>
                </template>
              </BaseTextArea>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
    </slot>

    <div v-if="errors.length" class="py-1">
      <p class="red--text ml-3 mb-0" style="font-size: 12px">
        {{ errors[0] }}
      </p>
    </div>
  </v-card>
</template>

<script lang="ts">
export default {
  data() {
    return {
      textValue: null,
    };
  },
  methods: {
    editItem(index) {
      this.textValue = this.value[index];
      this.$emit(
        'change',
        this.value.filter((item, itemIndex) => index !== itemIndex),
      );
    },
    deleteItem(index) {
      this.$emit(
        'change',
        this.value.filter((item, itemIndex) => index !== itemIndex),
      );
    },
    addItem(value) {
      if (value) {
        this.$emit('change', this.value.concat(value));
        this.textValue = null;
      }
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    readonly: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.light-edison__base-text-list .v-list-item__subtitle {
  text-overflow: clip;
  white-space: normal;
}
</style>
