export default class User {
  uid?: string;
  displayName: string;
  email: string;
  phoneNumber: string;

  constructor({ uid = null, displayName = null, email = null, phoneNumber = null }) {
    this.uid = uid;
    this.displayName = displayName;
    this.email = email;
    this.phoneNumber = phoneNumber;
  }
}
