import { querySnapshotToArray, firestoreDocument, withQuery } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';
import { deleteImages, pictureUpload } from '@/services/firebase/StorageService.js';

import firebase from 'firebase';
import isEqual from 'lodash/isEqual';

export const namespaced = true;

export const state = {
  vehicles: [],
  subscription: null,
  statements: [],

  //
  maintenanceRecords: [],
  maintenanceRecordsSubscription: null,
  maintenanceRecordsStatements: [],
};

export const getters = {
  vehicles: (state) => state.vehicles,
  maintenanceRecords: (state) => state.maintenanceRecords,
};

export const mutations = {
  SET_VEHICLES(state, vehicles) {
    state.vehicles = vehicles;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
  SET_STATEMENTS(state, statements) {
    state.statements = statements;
  },
  SET_MAINTENANCE_RECORDS(state, maintenanceRecords) {
    state.maintenanceRecords = maintenanceRecords;
  },
  SET_MAINTENANCE_RECORDS_SUBSCRIPTION(state, subscription) {
    state.maintenanceRecordsSubscription = subscription;
  },
  SET_MAINTENANCE_RECORDS_STATEMENTS(state, statements) {
    state.maintenanceRecordsStatements = statements;
  },
};

export const actions = {
  fetchVehicles({ state, commit }, statements = []) {
    if (state.subscription) {
      if (!isEqual(statements, state.statements)) {
        state.subscription();
        commit('SET_SUBSCRIPTION', null);
      } else {
        return;
      }
    }

    const firestore = firebase.firestore();
    const vehiclesCollection = firestore.collection('vehicles');
    const collection = withQuery(statements)(vehiclesCollection);

    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_VEHICLES', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
    commit('SET_STATEMENTS', statements);
  },
  saveVehicle(context, vehicleOptions) {
    return saveRecord(vehicleOptions).then((id) => id);
  },
  fetchVehicle(context, id) {
    return new Promise((resolve, reject) => {
      const vehicleDocument = firestoreDocument('vehicles')(id);
      vehicleDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteVehicle({ dispatch }, id) {
    if (!id) {
      throw new Error('Vehicle ID is required.');
    }
    const firestore = firebase.firestore();
    const vehicleDocument = firestore.doc(`vehicles/${id}`);
    const maintenanceRecordsCollection = firestore.collection('vehicles').doc(id).collection('maintenanceRecords');

    const maintenanceRecordsToDeletePromises = (maintenanceRecords) =>
      Promise.all(
        maintenanceRecords.map((record) =>
          dispatch('deleteMaintenanceRecord', {
            id: record.id,
            vehicleId: id,
          }),
        ),
      );

    return maintenanceRecordsCollection
      .get()
      .then((querySnapshot) => querySnapshotToArray(querySnapshot))
      .then((maintenanceRecords) => maintenanceRecordsToDeletePromises(maintenanceRecords))
      .then(() => vehicleDocument.delete());
  },
  fetchMaintenanceRecords({ state, commit }, { vehicleId, statements = [] }) {
    if (!vehicleId || !Array.isArray(statements)) {
      throw new Error('Invalid values for "vehicleId" or "statements" properties');
    }

    if (state.maintenanceRecordsSubscription) {
      if (!isEqual(statements, state.maintenanceRecordsStatements)) {
        state.subscription();
        commit('SET_MAINTENANCE_RECORDS_SUBSCRIPTION', null);
      } else {
        return;
      }
    }

    const firestore = firebase.firestore();
    const maintenanceRecordsCollection = firestore
      .collection('vehicles')
      .doc(vehicleId)
      .collection('maintenanceRecords');

    const collection = withQuery(statements)(maintenanceRecordsCollection);

    const maintenanceRecordsSubscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_MAINTENANCE_RECORDS', querySnapshotToArray(querySnapshot));
    });

    commit('SET_MAINTENANCE_RECORDS_SUBSCRIPTION', maintenanceRecordsSubscription);
    commit('SET_MAINTENANCE_RECORDS_STATEMENTS', statements);
  },
  fetchMaintenanceRecord(context, { maintenanceRecordId, vehicleId }) {
    if (!maintenanceRecordId || !vehicleId) {
      throw new Error('Maintenance Record ID and Vehicle ID are required.');
    }

    return new Promise((resolve, reject) => {
      const firestore = firebase.firestore();
      const maintenanceRecord = firestore
        .collection('vehicles')
        .doc(vehicleId)
        .collection('maintenanceRecords')
        .doc(maintenanceRecordId);

      maintenanceRecord
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteMaintenanceRecord(context, { vehicleId, id }) {
    if (!vehicleId || !id) {
      throw new Error('vehicle ID and maintenance record ID are required.');
    }

    const firestore = firebase.firestore();
    const maintenanceRecordDocument = firestore.doc(`vehicles/${vehicleId}/maintenanceRecords/${id}`);

    return maintenanceRecordDocument
      .get()
      .then((querySnapshot) => ({
        ...querySnapshot.data(),
        id: querySnapshot.id,
      }))
      .then((record) => deleteImages(record.pictures))
      .then(() => maintenanceRecordDocument.delete());
  },
  async saveMaintenanceRecord(context, saveConfiguration) {
    const firestore = firebase.firestore();

    const { vehicleId, id, data, pictures, parentFolder } = saveConfiguration;

    return pictureUpload(pictures, parentFolder, deleteImages).then((pictureUrls) => {
      const formObject = {
        ...data,
        pictures: [...data.pictures, ...pictureUrls],
      };

      const maintenanceRecordsCollection = firestore
        .collection('vehicles')
        .doc(vehicleId)
        .collection('maintenanceRecords');

      const maintenanceRecordsDocument = id ? maintenanceRecordsCollection.doc(id) : maintenanceRecordsCollection.doc();

      return maintenanceRecordsDocument[id ? 'update' : 'set'](formObject);
    });
  },
};
