export enum TextEditorToolbarOptions {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Strike = 'strike',
  Color = 'color',
  Highlight = 'highlight',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
  BulletList = 'bulletList',
  OrderedList = 'orderedList',
  TaskList = 'taskList',
  Indent = 'indent',
  Outdent = 'outdent',
  Link = 'link',
  Table = 'table',
  Blockquote = 'blockquote',
  HorizontalRule = 'rule',
  Code = 'code',
  CodeBlock = 'codeBlock',
  Clear = 'clear',
  Undo = 'undo',
  Redo = 'redo',
  Separator = '|',
}

export const DEFAULT_TOOLBAR = [
  TextEditorToolbarOptions.Undo,
  TextEditorToolbarOptions.Redo,
  TextEditorToolbarOptions.Clear,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.Bold,
  TextEditorToolbarOptions.Italic,
  TextEditorToolbarOptions.Underline,
  TextEditorToolbarOptions.Strike,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.P,
  TextEditorToolbarOptions.H1,
  TextEditorToolbarOptions.H2,
  TextEditorToolbarOptions.H3,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.Indent,
  TextEditorToolbarOptions.Outdent,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.Left,
  TextEditorToolbarOptions.Center,
  TextEditorToolbarOptions.Right,
  TextEditorToolbarOptions.Justify,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.BulletList,
  TextEditorToolbarOptions.OrderedList,
  TextEditorToolbarOptions.TaskList,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.Color,
  TextEditorToolbarOptions.Highlight,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.Blockquote,
  TextEditorToolbarOptions.Code,
  TextEditorToolbarOptions.CodeBlock,

  TextEditorToolbarOptions.Separator,

  TextEditorToolbarOptions.Link,
  TextEditorToolbarOptions.HorizontalRule,
  TextEditorToolbarOptions.Table,
];
