import Vue from 'vue';
import Vuex from 'vuex';

// Vuex Modules
import contacts from '@/store/modules/contacts.js';
import orgchart from '@/store/modules/orgchart';
import spreadsheetFormats from '@/store/modules/spreadsheetFormats.js';
import timeEntry from '@/store/modules/timeEntry.js';
import fieldOperations from './modules/fieldOperations.js';
import quarterlyEvaluations from './modules/quarterlyEvaluation';
import trainingVideos from './modules/trainingVideo';
import utils from './modules/utils.js';
import vacationRequests from './modules/vacationRequest';
import weeklyOverviews from './modules/weeklyOverview';

//
import * as checklists from '@/store/modules/field_operations/checklists.js';
import * as vehicles from '@/store/modules/field_operations/vehicles.js';
import * as improvementRequests from '@/store/modules/improvementRequests.js';
import * as notifications from '@/store/modules/notificationsModule.js';
import * as roles from '@/store/modules/roles.js';
import * as users from '@/store/modules/users.js';

// CRM store modules
import * as accounting from '@/store/modules/customer_relationships_management/accounting.js';
import * as accounts from '@/store/modules/customer_relationships_management/accounts.js';
import * as contracted from '@/store/modules/customer_relationships_management/contracted.js';
import * as designs from '@/store/modules/customer_relationships_management/designs.js';
import * as facilities from '@/store/modules/customer_relationships_management/facilities.js';
import * as opportunities from '@/store/modules/customer_relationships_management/opportunities.js';
import * as preContracting from '@/store/modules/customer_relationships_management/preContracting.js';
import * as projectEngines from '@/store/modules/customer_relationships_management/projectEngines.js';
import * as proposalRequests from '@/store/modules/customer_relationships_management/proposalRequests.js';
import * as returns from '@/store/modules/customer_relationships_management/returns.js';
import * as salesforceContacts from '@/store/modules/customer_relationships_management/salesforceContacts.js';
import * as services from '@/store/modules/customer_relationships_management/services.js';
import * as sops from '@/store/modules/customer_relationships_management/sops.js';
import * as workOrders from '@/store/modules/customer_relationships_management/workOrders.js';
import departments from '@/store/modules/departments';
import projectFinance from '@/store/modules/projectFinance';
import projectFinanceGroupTotals from '@/store/modules/projectFinanceGroupTotals';
import projectFinanceServices from '@/store/modules/projectFinanceServices';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    fieldOperations,
    utils,
    timeEntry,
    contacts,
    spreadsheetFormats,
    improvementRequests,
    notifications,
    users,
    vehicles,
    weeklyOverviews,
    quarterlyEvaluations,
    vacationRequests,
    trainingVideos,
    roles,
    checklists,
    departments,

    orgchart,
    projectFinance,
    projectFinanceServices,
    projectFinanceGroupTotals,

    // CRM migration modules
    accounts,
    facilities,
    salesforceContacts,
    opportunities,
    designs,
    services,
    projectEngines,
    preContracting,
    contracted,
    accounting,
    sops,
    proposalRequests,
    workOrders,
    returns,
  },
  state: {
    recentOpportunities: [],
    designPrimaryProducts: [],
    designControlProducts: [],
    designAccessoryProducts: [],
    deletedPrimaryProducts: [],
    deletedControlProducts: [],
    deletedAccessoryProducts: [],
    selectedIndex: '',
    spaceId: '',
    opportunityId: '',
    designId: '',
    selectProductId: '',
    productAddedTODesignBox: false,
    returnToDesignManualPopup: false,
    returnToAllExPopup: false,
    returnToMissingData: false,
    goToInstallationPage: false,
    goToDesignsPage: false,
    selectedDesign: '',
    selectedDesignSpaceId: '',
    selectedExistingCode: '',
    spaceID_ECM: '',
    ECMData: {
      ecmType: '',
      p1: '',
      p2: '',
      c1: '',
      c2: '',
      c3: '',
      a1: '',
      a2: '',
      a3: '',
    },
    returnToECM: false,
    // Edit ECM data
    returnToECMEdit: false,
    editSelectedECMLineNumber: '',
    editECMData: {
      ecmType: '',
      p1: '',
      p2: '',
      c1: '',
      c2: '',
      c3: '',
      a1: '',
      a2: '',
      a3: '',
    },
  },
  mutations: {
    update_selected_index(state, index) {
      state.selectedIndex = index;
    },
    design_url_ids(state, ids) {
      state.spaceId = ids.spaceId;
      state.opportunityId = ids.opportunityId;
      state.designId = ids.designId;
      console.log('Design ID = ' + state.designId);
    },
    selected_primary_productId(state, id) {
      // console.log('SpaceID = '+state.spaceId);
      // console.log('OpportunityID = '+state.opportunityId);
      console.log('Selected Product ID = ' + id);
      console.log('Selected Product Index = ' + state.selectedIndex);
      let selectedPrimaryIndex = state.selectedIndex;
      state.designPrimaryProducts[selectedPrimaryIndex] = id;
    },
    selected_control_productId(state, id) {
      // console.log('SpaceID = '+state.spaceId);
      // console.log('OpportunityID = '+state.opportunityId);
      console.log('Selected Product ID = ' + id);
      console.log('Selected Product Index = ' + state.selectedIndex);
      let selectedControlIndex = state.selectedIndex;
      state.designControlProducts[selectedControlIndex] = id;
    },
    selected_accessory_productId(state, id) {
      // console.log('SpaceID = '+state.spaceId);
      // console.log('OpportunityID = '+state.opportunityId);
      console.log('Selected Product ID = ' + id);
      console.log('Selected Product Index = ' + state.selectedIndex);
      let selectedAccessoryIndex = state.selectedIndex;
      state.designAccessoryProducts[selectedAccessoryIndex] = id;
    },
    reset_state(state) {
      state.designAccessoryProducts = [];
      state.designControlProducts = [];
      state.designPrimaryProducts = [];
      state.deletedPrimaryProducts = [];
      state.deletedControlProducts = [];
      state.deletedAccessoryProducts = [];
      state.selectedIndex = '';
      state.spaceId = '';
      state.opportunityId = '';
    },
  },
  actions: {
    updateSelectedIndex({ commit }, index) {
      commit('update_selected_index', index);
    },
    setDesignURLIds({ commit }, ids) {
      commit('design_url_ids', ids);
    },
    setPrimaryProductId({ commit }, id) {
      commit('selected_primary_productId', id);
    },
    setControlProductId({ commit }, id) {
      commit('selected_control_productId', id);
    },
    setAccessoryProductId({ commit }, id) {
      commit('selected_accessory_productId', id);
    },
    resetState({ commit }) {
      commit('reset_state');
    },
  },
});
