import { getAllRecords, querySnapshotToArray, firestoreDocument } from '@/services/utils/Utils.js';

import { saveRecord } from '@/services/firebase/FirestoreService.js';

import firebase from 'firebase';

export const namespaced = true;

export const state = {
  facilities: [],
  subscription: null,
};

export const getters = {
  facilities: ({ facilities }) => facilities,
  facility: (state) => (id) => state.facilities.find((facility) => facility.id === id),
};

export const mutations = {
  SET_FACILITIES(state, facilities) {
    state.facilities = facilities;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export const actions = {
  fetchFacilities({ state, commit }) {
    if (state.subscription) {
      return;
    }

    const collection = firebase.firestore().collection('facilities');
    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_FACILITIES', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  saveFacility(context, facilityOptions) {
    return saveRecord(facilityOptions).then((id) => id);
  },
  fetchFacility({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (getters.facility(id)) {
        resolve(getters.facility(id));
        return;
      }

      const facilityDocument = firestoreDocument('facilities')(id);
      facilityDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
